import { url, model } from '@/configs/global'

class Ativo extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.model_type = 'ativo'
        this.allowRemove = true

        this.listaCriacao = [
            {label: 'description', type: 'text', labelInput: 'Descrição'},
            {label: 'patrimony', type: 'text', labelInput: 'Patrimônio'},
            {label: 'category_id', type: 'select', textBy: 'description', valueBy: 'id', trackBy: 'id', labelInput: 'Categoria',
            options: ({getters}) => {
                return getters['categorias.get']['ativos']
            }},
        ]
        this.listaAtualizacao = [
            ...this.listaCriacao,
            {label: 'status', type: 'select', textBy: 'label', valueBy: 'id', trackBy: 'id', labelInput: 'Situação',
            options: () => [{'id': 1, 'label': 'Ativado'}, {'id': 0, 'label': 'Desativado'}]},
        ]

        this.concat = ['status']
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/cad_ativo`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        return await this.axios.put(`${url}/api/${id_e}/update_ativo/${this.id}`, obj)
    }

    async delete({getters}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_ativo/${this.id}`)
    }

    // Evidencias

    async getAllPhotos({getters}){
        const id_e = getters['empresa.get'].id
        const res = await this.axios.get(`${url}/api/${id_e}/get_ativo_photos/${this.id}`)
        return res.data.data.map((obj)=>{
            return {
                ...obj,
                url: `https://px40-api.cme-eng.com.br/thumb/ativos/${obj.img}?c=${id_e}&s=${this.id}&h=250&w=250`
            }
        })
    }

    async deletePhoto({getters, id_image}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_ativo_image/${id_image}`)
    }

    async savePhoto({getters, blob}){

        const formData = new FormData()
        formData.append('file', blob)
        formData.append('ativo_id', this.id)
        formData.append('order', 1)
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_ativo_image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }
}

export default Ativo