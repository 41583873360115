<template>
<div class="workspaces">
    <WorkspacesModal1 v-if="getEmpresas.length > 0" :empresas="getEmpresas"/>
    <WorkspacesModal2 v-else/>
</div>
</template>

<script>
import WorkspacesModal1 from '@/components/Workspaces/WorkspacesModal1'
import WorkspacesModal2 from '@/components/Workspaces/WorkspacesModal2'
export default {
    name: 'Workspaces',
    components: {WorkspacesModal1, WorkspacesModal2},
    computed:{
        getEmpresas(){
			return this.$store.getters['empresas.get'];
		},
    }
}
</script>

<style>
.workspaces{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(0deg, #828691, var(--branco));
}
.workspaces-img01{
    width: 30%;
}
.workspaces-img02{
    width: 75%;
}
.workspaces-modal-buttons{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
}
</style>