<template>
    <img v-if="getEmpresa.url_image" :src="getEmpresa.getImageURL()" alt="" class="system-menu-img" >
    <EmpresaImageDefault v-else class="system-menu-img"/>
</template>

<script>
import EmpresaImageDefault from '../../utils/EmpresaImageDefault'
export default {
    name: 'SystemMenuImage',
    components: {EmpresaImageDefault},
    computed: {
        getEmpresa(){
			return this.$store.getters['empresa.get']
		}
    }
}
</script>

<style scoped>
.system-menu-img{
    width: 50px;
    height: 50px !important;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}


@media only screen and (max-width: 600px) {
    .system-menu-img{
        display: none;
    }
}
</style>