<template>
    <va-modal v-model="show" :hide-default-actions="true" :mobile-fullscreen="false" max-width="80vw">
		<div class="modal">
        <v-calendar
            class="modal-calendar"
            :attributes="attrs"
            is-expanded
            title-position="left"
            :masks="masks"
            disable-page-swipe
			is-dark
			@dayclick='dayClicked'>
        >
        </v-calendar>
        <div v-for="objeto in getServicesDay(selectedDay)" :key="objeto.id">
			<div class="card-service" @click="update(objeto)">
				<div style="font-weight: bold;" >{{objeto.request}}</div>  
				<div> <span style="font-weight: bold;"> Ativo: </span>  {{objeto.ativo.description}} </div> 
				{{getHours(objeto.dt_start_scheduled)}} - {{getHours(objeto.dt_end_scheduled)}}
				<va-icon size="medium" :name="objeto.finish == true ? 'done' : 'close'" :color="objeto.finish ? 'verde' : 'laranja'"/>
			</div>
		</div>
		</div>
    </va-modal>
</template>

<script>
export default {
    name: "ModalCalendar",
    props: ["objetos", "aba"],
    data() {
        return {
			selectedDay : new Date(),
        };
    },
    computed: {
        show: {
            get(){
                return this.$store.getters["servicos.getCalendar"];
            },
            set(value) {
                this.$store.dispatch("servicos.calendarChange", value);
            },
        },
		attrs() { 
			return [
                {
                    key: "today",
                    highlight: "gray",
                    dates: new Date(),
                },
                ...this.getServices(),
            ]
		},
    },
    methods: {
        update(objeto){
			this.$store.dispatch('edit-modal.editShow', {
				objeto: objeto,
                callback: this.aba.callback
			})
		},
        getServices() {
            let dados = [];
            for (let i = 0; i < this.objetos.length; i++) {
                if (this.objetos[i].dt_start_scheduled != null) {
                    let dates = {
                        start: new Date(this.objetos[i].dt_start_scheduled),
                        end: new Date(this.objetos[i].dt_end_scheduled),
                    };
                    let popover = {
                        label: this.objetos[i].request,
                    };
                    let dot = {
                        color: this.objetos[i].finish ? "green" : "red",
                    };
                    dados = dados.concat({dot, dates, popover});
                }
            }
            return dados;
        },
		dayClicked(day) {
			this.selectedDay = day.date;
			console.log(this.selectedDay);
			/*this.getServicesDay(this.selectedDay);*/
		},
		getServicesDay(day){
			let obj = []
			for (let i = 0; i < this.objetos.length; i++) {
                if (this.objetos[i].dt_start_scheduled != null) {
                    let startDay = new Date(this.objetos[i].dt_start_scheduled);
					let endDay = new Date(this.objetos[i].dt_end_scheduled);
					if (((day.getMonth() == startDay.getMonth()) && (day.getDate() >= startDay.getDate() && day.getDate() <= endDay.getDate()))||(day >= startDay && day <= endDay)){
						obj = obj.concat(this.objetos[i])
					}
                }
            }
			return obj
		},
		getHours(dateTime){
			return (new Date(dateTime)).toLocaleTimeString('pt-BR')
		}
    },
};
</script>

<style scoped>
.modal-calendar {
    min-width: 80vw;
    /*color: var(--branco);*/
}
.modal{
	min-width: 70vw ;
}
.card-service{
	margin-top: 5px;
	background-color: var(--c4);
	border-radius: 7px;
	color: var(--c3);
	padding: 5px;
}
.card-service:hover{
    opacity: 0.75;
    cursor: pointer;
}
</style>
