import { url, model } from '@/configs/global'

class PlanoFrequenciaTask extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.model_type = 'plano_frequencia_item'

        this.listaCriacao = [
            {label: 'task_description', type: 'text', labelInput: 'Descrição'},
        ]
        this.listaAtualizacao = this.listaCriacao

        this.concat = ['frequency_id']
        this.concatUpdate = ['frequency_id']
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_plan_item`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        return await this.axios.put(`${url}/api/${id_e}/update_plan_item/${this.id}`, obj)
    }

}

export default PlanoFrequenciaTask