<template>
   <div class="documentos-tabela">
	    <va-data-table v-if="objetos" hoverable :items="getObjetos" :columns="columns">
			<template #cell(actions)="{ rowIndex }">
				<va-button flat color="secondary" size="small" icon="edit" class="mr-1" @click="update(objetos[rowIndex])"></va-button>
				<va-button flat color="secondary" size="small" icon="visibility" @click="showChildren(objetos[rowIndex])"></va-button>
			</template>
			<template #cell(actions_relatorios)="{ }">
				<va-button flat color="secondary" size="small" icon="download" ></va-button>
			</template>
			<template #cell(created_at)="{ rowIndex }">
				{{timestamp(objetos[rowIndex].created_at)}}
			</template>
			<template #cell(updated_at)="{ rowIndex }">
				{{timestamp(objetos[rowIndex].updated_at)}}
			</template>
		</va-data-table>
   </div>
</template>

<script>
export default {
    name: 'DocumentosTabela',
	props : ['aba', 'objetos'],
	computed: {
		columns(){
			if(this.aba.tipo == 'planos'){
				return [
					{ key: 'description' , label:'Nome', sortable: true },
					{ key: 'tipo' , label: 'Tipo', sortable: true },
					{ key: 'privacidade' , label:'Privacidade', sortable: true },
					{ key: 'created_at' , label:'Criado em:', sortable: true },
					{ key: 'updated_at' , label:'Atualizado em:', sortable: true },
					{ key: 'actions', label: 'AÇÕES'}
				]
			}
			else if(this.aba.tipo == 'checklists'){
				return [
					{ key: 'description' , label:'Nome', sortable: true },
					{ key: 'privacidade' , label:'Privacidade', sortable: true },
					{ key: 'created_at' , label:'Criado em:', sortable: true },
					{ key: 'updated_at' , label:'Atualizado em:', sortable: true },
					{ key: 'actions', label: 'AÇÕES'}
				]
			}
			else if(this.aba.tipo == 'relatorios'){
				return [
					{ key: 'nome' , label:'Nome', sortable: true },
					{ key: 'actions_relatorios', label: 'AÇÕES'}
				]
			}
			else return []
		},
		getObjetos(){
			return this.objetos.map((obj)=>{
				return {
					...obj,
					privacidade: obj.public ? 'Público' : 'Privado',
					tipo: obj.type == 1 ? 'Manut.' : obj.type == 2 ? 'Oper.' : 'Cont.'
				}
			})
		}
	},
	methods:{
		update(objeto){
			this.$store.dispatch('edit-modal.editShow', {
				objeto: objeto,
                callback: this.aba.callback
			})
		},
		timestamp(time_str){
			var date = new Date(time_str)
			return date.toLocaleDateString('pt-BR')
		},
		async showChildren(obj){
			this.$store.dispatch('loading.show')
			let objetos = await obj.getChildren({getters: this.$store.getters})
			this.$store.dispatch('documentos.show', {objetos: objetos, parente: obj, aba: this.aba})
			this.$store.dispatch('loading.hide')
		}
	}
}
</script>

<style scoped>
.documentos-tabela {
	/*margin: 10px;*/
	background-color: var(--c1);
	color: var(--c3);
	min-height: 100%;
}
</style>