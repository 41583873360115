import { createApp } from 'vue'
import App from './App.vue'
import router from './configs/router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import FontAwesomeIcon from '@/configs/font-awesome'
import { vfmPlugin } from 'vue-final-modal'
import store from './store/__store__'
import options from './configs/toast';
import Toast from "vue-toastification";
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import '@/configs/echarts'
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

axios.defaults.headers.post['Content-Type'] = 'application/json';

store.axios = axios
store.$router = router

createApp(App)
    .component("fa", FontAwesomeIcon)
    .use(router)
    .use(VuesticPlugin,{
        colors: {
            verde: '#3CDE93', 
            laranja: '#F85B40',
            cinza: '#32353E',
            branco: '#FEFFFA',
            azul: '#3CB3DE',
            c3: '#CCCCCC',
            c1: '#3C3F4A',
            c5: '#636363',
        }
    })
    .use(store)
    .use(Toast, options)
    .use(vfmPlugin)
    .use(VueAxios, axios)
    .use(OpenLayersMap)
    .use(VCalendar, {})
    .mount('#app')
    