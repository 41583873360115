import { url, model } from '@/configs/global'

class Mantenedor extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.solicitacao = this.request
        this.model_type = 'mantenedor'
        this.allowRemove = true

        this.listaCriacao = [
            {label: 'name', type: 'text', labelInput: 'Nome'},
            {label: 'function', type: 'text', labelInput: 'Função'},
            {label: 'coust_hour', type: 'text', labelInput: 'Custo'},
            {label: 'source', type: 'select', textBy: 'label', valueBy: 'id', trackBy: 'id', labelInput: 'Origem',
            options: () => [{'id': 0, 'label': 'Interno'}, {'id': 1, 'label': 'Externo/Convidado'}]},
        ]
        this.listaAtualizacao = this.listaCriacao
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_maintainer`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        return await this.axios.put(`${url}/api/${id_e}/update_maintainer/${this.id}`, obj)
    }

    async delete({getters}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_maintainer/${this.id}`)
    }
}

export default Mantenedor