<template>
   <div class="patrimonio-estatistica">
	   <div class="patrimonio-estatistica-cards">
		   	<div v-for="categoria in getCategorias(objetos, aba.nome)" :key="categoria">
				   <PatrimonioEstatisticaCard :nome="categoria" :numero="getNumero(objetos, aba.nome, categoria)" />
			</div>
	   </div>
	   <div class="patrimonio-estatistica-ilustracao">
		    <div class="patrimonio-estatistica-caption">{{caption}}</div>
	   		<img v-if="img != ''" :src="require('@/assets/'+img)" alt="" class="patrimonio-estatistica-img">
	   </div>
   </div>
</template>

<script>
import PatrimonioEstatisticaCard from './PatrimonioEstatisticaCard'
export default {
    name: 'PatrimonioEstatistica',
	props : ['img', 'aba', 'objetos', 'caption'],
	components:{PatrimonioEstatisticaCard},
	computed: {
	},
	methods:{
		getCategorias(obj, aba){
			let CATEGORIAS = [];
			if(aba == 'Ativos'){
				for (let i = 0; i < obj.length; i++) {
					if(!CATEGORIAS.includes(obj[i].category.description)) CATEGORIAS=CATEGORIAS.concat(obj[i].category.description);
				}
			}
			else if(aba == 'Reports'){
				for (let i = 0; i < obj.length; i++) {
					if(!CATEGORIAS.includes(obj[i].criticality)) CATEGORIAS=CATEGORIAS.concat(obj[i].criticality);
				}
			}
			else if(aba == 'Serviços'){
				CATEGORIAS = ['Concluídos', 'Em aberto']
			}
			return CATEGORIAS
		},
		getNumero(obj, aba, categoria){
			let NUMERO = 0;
			if(aba == 'Ativos'){
				for (let i = 0; i < obj.length; i++) {
					if(obj[i].category.description == categoria) NUMERO = NUMERO + 1
				}
			}
			else if(aba == 'Reports'){
				for (let i = 0; i < obj.length; i++) {
					if(obj[i].criticality == categoria) NUMERO = NUMERO + 1
				}
			}
			else if(aba == 'Serviços'){
				for (let i = 0; i < obj.length; i++) {
					if(categoria == 'Concluídos'){
						if(obj[i].finish) NUMERO = NUMERO + 1
					}
					else{
						if(!obj[i].finish) NUMERO = NUMERO + 1
					}
				}
			}
			return NUMERO
		}
	}
}
</script>

<style scoped>
.patrimonio-estatistica {
	margin-top: 10px;
	grid-area: patrimonio-estatistica;
	background-color: var(--c1);
	display: flex;
	flex-direction: row;
}

.patrimonio-estatistica-cards{
	width: 60%;
	display: flex;
	flex-direction: row;
}

.patrimonio-estatistica-ilustracao{
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.patrimonio-estatistica-img{
	height: 80%;
	margin: 10px;
	/*scale: 10%;*/
}
.patrimonio-estatistica-caption{
	width: 40%;
	margin: 10px;
	color: var(--c3);
	align-self: center;
	font-size: 1.1em;
	/*scale: 10%;*/
}
</style>