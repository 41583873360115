<template>
    <div icon="autorenew" class="system-content">
        <div class="titulo" v-if="vIF">
            <div class="nomes">
                <div v-for="(aba, index) in getAbas" :key="index" class="nomes-card" @click="select_aba(index)" v-bind:id="aba_atual.nome == aba.nome ? 'background1' : 'background2'">
                    {{aba.nome}}
                </div>
            </div>
            <div class="filtro">
                <va-input v-if="showPesquisa" v-model="filtro" class="filtro" id="inputfiltro" color="verde"/>
                <va-button @click=changePesquisa() style="width: 40px;" color="secondary" flat size="medium" icon="search"></va-button>
            </div>
        </div>
        <div class="router-view-content"><router-view></router-view></div>
  	</div>
</template>

<script>
export default {
    name: 'SystemContent',
    data() {
        return {
            aba_atual: {},
            showPesquisa: false,
        }
    },
    computed: {
        getAbas(){
            return this.$store.getters['abas.getAbas'][this.$route.path]
        },
        getPath(){
            return this.$route.path
        },
        vIF(){
            return this.getAbas != null && this.getAbas != undefined && this.getAbas.length > 0
        },
        filtro: {
            get(){
			    return this.$store.getters['abas.getFiltro'][this.$route.path]
            },
            set(value){
                this.$store.dispatch('abas.setFiltro', [this.$route.path, value])
            }
        }
    },
    methods: {
		select_aba(index){
            if (this.vIF){
                this.aba_atual = this.getAbas[index]
                this.$store.dispatch('abas.set', [this.$route.path, index])
            }
		},
        changePesquisa(){
          this.showPesquisa = (this.showPesquisa ? false : true)
          if (this.showPesquisa) setTimeout(()=>document.getElementById("inputfiltro").focus(), 100)
          return this.showPesquisa
        }
    },
    watch: {
        getPath(){
            const index = this.$store.getters['abas.getIndices'][this.$route.path]
            this.select_aba(index != undefined ? index : 0) 
        },
    },
    mounted() {
        const index = this.$store.getters['abas.getIndices'][this.$route.path]
        this.select_aba(index != undefined ? index : 0)
    },
}
</script>

<style scoped>
.system-content{
    grid-area: system-content;
    background-color: var(--cinza);
    overflow-y: auto;
    padding: 10px;
}
.router-view-content{
    height: 95%;
}
.titulo{
    height: 5%;
	background-color: var(--c1);
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
.nomes {
	display: flex;
	flex-wrap: wrap;
}
.filtro {
	display: flex;
}
.nomes-card {
	padding: 10px 25px;
	color: var(--branco);
	display: flex;
	justify-content: center;
	align-items: center;
    /*border-radius: 0px 15px 0px 0px;*/
    height: 100%;
    font-size: 0.9rem;
}
.nomes-card:hover {
	cursor: pointer;
	opacity: 0.8;
}
#background1{
    background-color: var(--c4);
    font-style: italic;
}
#background2{
	background-color: var(--c1);
}
@media only screen and (max-width: 800px) {
	.titulo, .nomes{
        /*flex-wrap: wrap;*/
        flex-direction: column;
        justify-content: left;
		font-size: 1em;
	}
	.nomes-card {
		padding: 8px 8px;
       
	}
    .filtro{
        display: none;
    }

}
</style>