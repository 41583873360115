import Usuario from '@/models/usuario'
import { url } from '@/configs/global'

export default {
    state: () => ({
        membros: [],
    }),
    mutations: {
        'membros.set': function(state, {membros}){
            if(membros != undefined) state.membros = membros
        },
    },
    getters: {
        'membros.get': function(state){
            return state.membros
        }
    },
    actions: {
        'membros.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/getall_members_company`)
			.then((response) => {
                commit('membros.set', {
                    membros: response.data.data.map(obj => new Usuario({...obj.user, level_permission: obj.level_permission}, this.axios))
                        .sort((a, b) => a.name.localeCompare(b.name))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}