export default {
    state: () => ({
        show: false,
        objeto: {}
    }),
    mutations: {
        'evidencias.set': function(state, {show, objeto}){
            if(show != undefined) state.show = show
            if(objeto != undefined) state.objeto = objeto
        }
    },
    getters: {
        'evidencias.getShow': function(state){
            return state.show
        },
        'evidencias.getObjeto': function(state){
            return state.objeto
        }
    },
    actions: {
		'evidencias.show': function({commit}, objeto){
            commit('evidencias.set', { show: true, objeto: objeto})
        },
		'evidencias.change_show': function( {commit}, value){
            commit('evidencias.set', { show: value })
        }
    }
}