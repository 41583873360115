<template>
    <va-modal class="workspaces-modal2" v-model="showModal" color="dark" no-dismiss="false" :hide-default-actions="true" overlay-opacity="0">
		<div style="color: white;">Você ainda não está cadastrado em workspaces</div>
        <div class="workspaces-modal-buttons">
            <va-button class="mt-3" color="azul" @click="criar">Criar um workspace</va-button> 
            <va-button class="mt-3" color="azul" @click="sair">Sair</va-button>
        </div>
	</va-modal>
</template>

<script>
export default {
    name: 'WorkspacesModal2',
    data: () => ({
        showModal: true,
    }),
	methods:{
		sair(){
            this.$store.dispatch('login.logout');
            this.$router.push('/');
		},
        criar(){
            this.$store.dispatch('edit-modal.editShow', {
				objeto: 'empresa',
                callback: 'usuario.validate'
			})
        }
	}
}
</script>

<style scoped>
</style>