import { url, model } from '@/configs/global'

class ChecklistSectionItem extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.model_type = 'checklist_section_item'

        this.listaCriacao = [
            {label: 'description', type: 'text', labelInput: 'Nome'},
        ]
        this.listaAtualizacao = this.listaCriacao

        this.concat = ['checklist_section_id']
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_checklist_item`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        return await this.axios.put(`${url}/api/${id_e}/update_checklist_item/${this.id}`, obj)
    }
}

export default ChecklistSectionItem