import { url, model } from '@/configs/global'

class Setor extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.solicitacao = this.request
        this.model_type = 'setor'
        this.allowRemove = true

        this.listaCriacao = [
            {label: 'description', type: 'text', labelInput: 'Nome'},
        ]
        this.listaAtualizacao = this.listaCriacao
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_company_sector`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        return await this.axios.put(`${url}/api/${id_e}/update_company_sector/${this.id}`, obj)
    }

    async delete({getters}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_company_sector/${this.id}`)
    }
}

export default Setor