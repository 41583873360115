<template>
<div class="index">
    <IndexLogin/>
    <div class="topbar-slideshow">
        <IndexTopbar/>
        <IndexPrincipal />
    </div>
    <span class="index-span000">
        <IndexSolutions />
        <IndexFooter />
    </span>
</div>
</template>

<script>
import IndexPrincipal from '@/components/Index/IndexPrincipal'
import IndexSolutions from '@/components/Index/IndexSolutions'
import IndexLogin from '@/components/Index/IndexLogin'
import IndexFooter from '@/components/Index/IndexFooter'
import IndexTopbar from '@/components/Index/IndexTopbar'
export default {
    name: 'Index',
    components: {IndexPrincipal, IndexSolutions,IndexLogin, IndexFooter, IndexTopbar}
}
</script>

<style scoped>
.index{
    overflow-y: scroll;
    overflow-x: hidden;
}
.topbar-slideshow{
    width: 100%;
    height: 100vh;
    display: flex;
    /*grid-template-rows: 15vh 85vh;*/
    /*grid-template-columns: 1fr;*/
    /*grid-template-areas: 
            "index-topbar"
            "index-principal";*/
    /*position: relative;*/
}

@media only screen and (max-width: 600px) {
    .index-span000{
        display: none;
    }
    .topbar-slideshow{
        grid-template-rows: 35vh 65vh;
    }
}
</style>