<template>
  <FloatButton @click="refresh" icon="refresh" type="vuestic" />
  <FloatButton
    v-if="aba.model"
    @click="add"
    icon="add"
    type="vuestic"
    style="bottom: 90px"
  />
  <FloatButton
    v-if="aba.tipo == 'servicos'"
    @click="openCalendar"
    icon="calendar_month"
    type="vuestic"
    style="bottom: 160px"
  />
  <div class="system-patrimonio">
    <PatrimonioEstatistica
      v-if="aba.tipo == 'ativos'"
      :objetos="getObjetos"
      :aba="aba"
      img="ativos.svg"
      caption='"Se você pode tocar, cadastre aqui."'
    />
    <PatrimonioEstatistica
      v-if="aba.tipo == 'reports'"
      :objetos="getObjetos"
      :aba="aba"
      img="reports.svg"
      caption='"Se você não pode tocar, cadastre aqui."'
    />
    <PatrimonioEstatistica
      v-if="aba.tipo == 'servicos'"
      :objetos="getObjetos"
      :aba="aba"
      img=""
      caption=""
    />
    <PatrimonioTabela :objetos="getObjetos" :aba="aba" />
    <!--<div v-for="objeto in getObjetos" :key="objeto.id">
			<div style="padding: 10px;">{{objeto}}</div>
		</div>-->
  </div>
  <ModalCalendar :objetos="getObjetos" :aba="aba"> </ModalCalendar>
</template>

<script>
import FloatButton from "@/utils/FloatButton";
import PatrimonioEstatistica from "@/components/Patrimonio/PatrimonioEstatistica";
import PatrimonioTabela from "@/components/Patrimonio/PatrimonioTabela";
import ModalCalendar from "@/components/Patrimonio/ModalCalendar";
export default {
  name: "SystemPatrimonio",
  components: {
    FloatButton,
    PatrimonioEstatistica,
    PatrimonioTabela,
    ModalCalendar,
  },
  data: () => ({
    open: false,
    filtro: "",
  }),
  computed: {
    getObjetos() {
      if (this.aba.tipo == "ativos") return this.$store.getters["ativos.get"];
      if (this.aba.tipo == "reports") return this.$store.getters["reports.get"];
      if (this.aba.tipo == "servicos") return this.$store.getters["servicos.get"];
      return [];
    },
    getFiltro() {
      return this.$store.getters["abas.getFiltro"][this.$route.path];
    },
    aba() {
      const index = this.$store.getters["abas.getIndices"][this.$route.path];
      const abas = this.$store.getters["abas.getAbas"][this.$route.path];
      return index != undefined ? abas[index] : {};
    },
  },
  watch: {
    aba() {
      this.loadData();
    },
  },
  methods: {
    add() {
      this.$store.dispatch("edit-modal.editShow", {
        objeto: this.aba.model,
        callback: this.aba.callback,
      });
    },
    openCalendar() {
      this.$store.dispatch("servicos.calendar");
      console.log("Teste");
    },
    refresh() {
      this.loadData();
    },
    loadData() {
      if (this.aba && this.aba.callback) this.$store.dispatch(this.aba.callback);
    },
  },
  mounted() {
    this.loadData();
    this.$store.dispatch("categorias.load", "ativos");
    this.$store.dispatch("ativos.load");
  },
};
</script>

<style scoped>
.system-patrimonio {
  margin: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 150px 1fr;
  grid-template-areas:
    "patrimonio-estatistica"
    "patrimonio-tabela";
}
.ativos-filter {
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--c5) !important;
}

.ativos-filter-input {
  color: white !important;
}

.red {
  color: red;
}
</style>
