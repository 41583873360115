import { url, model } from '@/configs/global'

class Usuario extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.model_type = 'usuario'
        this.listaCriacao = [
            {label: 'NOME', type: 'text'},
            {label: 'USUARIO', type: 'text'}
        ]
        this.listaAtualizacao = this.listaCriacao
    }

    async create(){
        return await this.axios.post(`${url}/register`, {...this})
    }
    
    static async getLoggedUser(axios){
        const res = await axios.get(`${url}/api/get_logged_user`)
        return res.data
    }

    static async getAllCompanies(axios){
        const res = await axios.get(`${url}/api/get_companies`)
        return res.data.data
    }
}

export default Usuario