import Usuario from '../models/usuario'
import Empresa from '../models/empresa'
import Ativo from '../models/ativo'
import Report from '../models/report'
import Servico from '../models/servico'
import Setor from '../models/setor'
import Projeto from '../models/projeto'
import Mantenedor from '../models/mantenedor'
import PlanoFrequencia from '../models/plano_frequencia'
import ChecklistSection from '../models/checklist_section'
import Plano from '../models/plano'
import Checklist from '../models/checklist'
import ChecklistSectionItem from '../models/checklist_section_item'
import PlanoFrequenciaItem from '../models/plano_frequencia_item'

export default {
    state: () => ({
        editShow: false,
        editObjeto: {},
        callback: null
    }),
    mutations: {
        'edit-modal.set': function(state, {editShow, editObjeto, callback}){
            if(editShow != undefined) state.editShow = editShow
            if(editObjeto != undefined) state.editObjeto = editObjeto
            if(callback != undefined) state.callback = callback
        }
    },
    getters: {
        'edit-modal.getEditShow': function(state){
            return state.editShow
        },
        'edit-modal.getEditObjeto': function(state){
            return state.editObjeto
        },
        'edit-modal.getCallback': function(state){
            return state.callback
        }
    },
    actions: {
		'edit-modal.editShow': function({commit}, {objeto, callback, id_parente}){
            if (objeto == 'usuario')     objeto = new Usuario ({  }, this.axios)
            if (objeto == 'empresa')     objeto = new Empresa ({  }, this.axios)
            if (objeto == 'ativo')     objeto = new Ativo ({ status: 1 }, this.axios)
            if (objeto == 'report')     objeto = new Report ({ status: 1 }, this.axios)
            if (objeto == 'servico')     objeto = new Servico ({ }, this.axios)
            if (objeto == 'setor')     objeto = new Setor ({ }, this.axios)
            if (objeto == 'projeto')     objeto = new Projeto ({ }, this.axios)
            if (objeto == 'mantenedor')     objeto = new Mantenedor ({ }, this.axios)
            if (objeto == 'plano')     objeto = new Plano ({ }, this.axios)
            if (objeto == 'plano_frequencia')     objeto = new PlanoFrequencia ({ plan_id: id_parente }, this.axios)
            if (objeto == 'plano_frequencia_item')     objeto = new PlanoFrequenciaItem ({ frequency_id: id_parente }, this.axios)
            if (objeto == 'checklist')     objeto = new Checklist ({ }, this.axios)
            if (objeto == 'checklist_section')     objeto = new ChecklistSection ({ checklist_id: id_parente }, this.axios)
            if (objeto == 'checklist_section_item')     objeto = new ChecklistSectionItem ({ checklist_section_id: id_parente }, this.axios)
            commit('edit-modal.set', { editShow: true, editObjeto: objeto, callback: callback })
        },
		'edit-modal.change_editShow': function( {commit}, value){
            commit('edit-modal.set', { editShow: value })
        }
    }
}