<template>
   <div class="empresa-estatistica">
	   <div class="empresa-estatistica-cards">
		   	<div v-for="categoria in CATEGORIAS" :key="categoria">
				   <EmpresaEstatisticaCard :nome="categoria" :numero="getNumero(categoria, objetos)" />
			</div>
	   </div>
	   <div class="empresa-estatistica-ilustracao">
		    <div class="empresa-estatistica-caption">{{caption}}</div>
	   		<img :src="require('@/assets/'+img)" alt="" class="empresa-estatistica-img">
	   </div>
   </div>
</template>

<script>
import EmpresaEstatisticaCard from './EmpresaEstatisticaCard'
export default {
    name: 'EmpresaEstatistica',
	props : ['img', 'aba', 'objetos', 'caption'],
	components:{EmpresaEstatisticaCard},
	computed: {
	},
	data: () => ({
		CATEGORIAS: ['Interno', 'Externo/Convidado']
	}),
	methods:{
		getNumero(categoria, obj){
			let NUMERO = 0;
			if(categoria == 'Interno'){
				for (let i = 0; i < obj.length; i++) {
					if(obj[i].source == 0) NUMERO = NUMERO + 1
				}
			}
			else if(categoria == 'Externo/Convidado'){
				for (let i = 0; i < obj.length; i++) {
					if(obj[i].source == 1) NUMERO = NUMERO + 1
				}
			}
			return NUMERO
		}
	}
}
</script>

<style scoped>
.empresa-estatistica {
	margin-bottom: 10px;
	background-color: var(--c1);
	display: flex;
	flex-direction: row;
	height: 130px;
}

.empresa-estatistica-cards{
	width: 60%;
	display: flex;
	flex-direction: row;
}

.empresa-estatistica-ilustracao{
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.empresa-estatistica-img{
	height: 80%;
	margin: 10px;
}
.empresa-estatistica-caption{
	width: 40%;
	margin: 10px;
	color: var(--c3);
	align-self: center;
	font-size: 1.1em;
}
</style>