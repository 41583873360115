import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    state: () => ({
        
    }),
    mutations: {

    },
    getters: {
        
    },
    actions: {
        'toast.success': function( { state }, msg ){
            console.log(state)
            if (msg != undefined || msg == ""){
                toast.success(msg)
            }else{
                toast.success('Requisição executado com sucesso')
            }
        },
        'toast.error': function( { state }, msg ){
            if(state == msg) console.log(state)
            let message = ''
            try {
                if (msg != undefined || msg != ""){
                    if(msg.response){
                        console.log(msg.response)
                        if(msg.response.data){
                            let objeto = msg.response.data
                            if (objeto.data) objeto = objeto.data
                            else objeto = objeto.message
                            if (typeof(objeto) == 'object'){
                                const array = Object.values(objeto)[0]
                                message = array[array.length-1]
                            }else{
                                message = objeto
                            }
                        }else{
                            message = msg.response.data
                        }
                    }else{
                        message = msg
                    }
                    toast.error(message)
                }else{
                    throw 0
                } 
            } catch {
                toast.error('Erro inesperado')
            }
            
        }
    }
}