import Servico from '@/models/servico'
import { url } from '@/configs/global'

export default {
    state: () => ({
        show: false,
        servicos: [],
    }),
    mutations: {
        'servicos.set': function(state, {servicos}){
            if(servicos != undefined) state.servicos = servicos
        },
        'servicos.setCalendar': function(state, show){
            state.show = show
            console.log('servicos.setCalendar')
            console.log(state.show)
        },
    },
    getters: {
        'servicos.get': function(state){
            return state.servicos
        },
        'servicos.getCalendar': function(state){
            console.log('servicos.getCalendar')
            return state.show
        }
    },
    actions: {
        'servicos.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_services`)
			.then((response) => {
                commit('servicos.set', {
                    servicos: response.data.data.map(obj => new Servico(obj, this.axios)).sort((a, b) => a.request.localeCompare(b.request))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        },
        'servicos.calendar': function({commit}){
            commit( 'servicos.setCalendar', true );
            console.log('servicos.calendar')
        },
        'servicos.calendarChange': function({commit},value){
            commit( 'servicos.setCalendar', value );
            console.log('servicos.calendarChange')
        },
    }
}