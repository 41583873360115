<template>
<div class="system">
    <SystemFooter v-if="0"/>
    <SystemMenu/>
    <SystemTopbar/>
    <SystemContent/>
</div>
</template>

<script>
import SystemFooter from "@/components/System/SystemFooter"
import SystemMenu from "@/components/System/SystemMenu"
import SystemTopbar from "@/components/System/SystemTopbar"
import SystemContent from "@/components/System/SystemContent"
export default {
    name: 'System',
    components: {SystemFooter, SystemMenu, SystemTopbar, SystemContent},
}
</script>

<style>
.system{
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 70px 1fr;
    grid-template-areas: 
        "system-menu system-topbar"
        "system-menu system-content"
        /*"system-menu system-footer"*/;
}


@media only screen and (max-width: 600px) {
    .system{
        height: 100vh;
        width: 100vw;
        display: grid;
        grid-template-rows: 40px 1fr 50px;
        grid-template-columns: 1fr;
        grid-template-areas: 
            "system-topbar"
            "system-content"
            "system-menu";
    }
}
</style>