<template>
    <div class="index-principal">
        <div class="index-principal-quadro">
            <div class="index-principal-caption"> Faça você mesmo! 
                <div class="index-principal-caption-subtitle">Gerencie seus serviços tudo em um único ambiente.</div>
                <va-button :rounded="false" color="verde" text-color="cinza" class="index-contato-button" @click="confirm">Contate-nos</va-button>
            </div>
            <img :src="require('@/assets/conecte.svg')" alt="" class="index-principal-img">
        </div>
        <!--
        <div class="index-principal-footer">
            <div class="index-principal-footer-text">#Experiência Personalizada</div>
            <div class="index-principal-footer-text">#Industria 4.0</div>
            <div class="index-principal-footer-text">#Transformação Digital</div>
        </div>
        -->
    </div>
</template>

<script>

export default {
    name: 'IndexPrincipal',
    methods: {
        confirm(){
            this.$router.push('/contatos');
        },
    }
}
</script>

<style scoped>

.index-principal{
    grid-area: index-principal;
    width: 100%;
    background-image: linear-gradient(135deg, #32353E, #2C9E6C);
}

.index-principal-quadro{
    display: flex;
    height: 100%;
    flex-direction: row;
}

.index-principal-caption{
    width: 45%;
    height: 80%;
    font-size: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 70px;
    padding: 150px;
    padding-right: 0;
    padding-left: 100px;
    color: var(--branco)
}

.index-principal-caption-subtitle{
    font-size: 0.5em;
}

.index-principal-img{
    width: 55%;
    padding: 30px;
    padding-top: 200px;
}

.index-contato-button{
    width: 200px;
    margin-top: 30px;
}

@media only screen and (max-width: 600px) {
    .index-principal-quadro{
        flex-direction: column;
    }
    .index-principal-caption{
        width: 100%;
        height: 50%;
        align-items: center;
        font-size: 1.7em;
        padding: 10px;
        text-align: center;
    }
    .index-principal-img{
        width: 100%;
        height: 50%;
    }/*
    .index-principal-caption{
        font-size: 2em;
        padding-top:10px;
        padding-left:100px ;
        padding-right:10px ;
    }*/
}
/*
@media only screen and (max-width: 1360px) {
    .index-principal-caption{
        font-size: 3.3em;
    }
}

@media only screen and (max-width: 1000px) {
    .index-principal-caption{
        font-size: 2.6em;
        padding-top:200px;
        padding-left:100px ;
        padding-right:10px ;
    }
}*/


/*
@media only screen and (max-height: 600px) {
    .index-principal-caption{
        font-size: 2em;
        padding-top:10px;
        padding-left:100px ;
        padding-right:10px ;
    }
}
*//*
@media only screen and (max-width: 800px) {
    .index-principal-quadro{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .index-principal-img{
        width: 100%;
        padding:50px;
        padding-top:10px;
    }
    .index-principal-caption{
        font-size: 2em;
        width: 100%;
        padding-top:10px;
        padding-left:20px;
        padding-right:20px;
        margin-top: 30px;
        text-align: center;
    }
    .index-principal-quadro{
        height: calc(100vh - 200px);
    }
    .index-principal{
        height:calc( 100vh - 200px);
    }
    .index-principal-footer{
        display: none;
    }
}*/
</style>