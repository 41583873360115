<template>
    <va-modal v-model="show" :hide-default-actions="true" :mobile-fullscreen="false" max-width="100vw">
	<div class="modal-documentos">
		<div class="modal-documentos-header">
			<h2>{{aba.tipo == 'planos'? 'LISTA DE SUBPLANOS' : 'LISTA DE SEÇÕES'}}</h2>
			<va-button color="verde" class="mb-3" icon="add"
				@click="update( aba.tipo == 'planos' ?  'plano_frequencia' : 'checklist_section', $store.getters['documentos.getParente'])"
					size="small">Adicionar {{aba.tipo == 'planos'? 'subplano' : 'seção'}}</va-button>
		</div>
		<div v-for="objeto in objetos" :key="objeto.id" class="modal-documentos-objeto">
			<div class="objeto-titulo">
				<!--<va-button color="secondary" flat :icon="(ativado && ativado.id == objeto.id) ? 'visibility_off' : 'visibility' " @click="ativado = (ativado && ativado.id == objeto.id) ? null : objeto" size="small" />-->
				<div @click="ativado = (ativado && ativado.id == objeto.id) ? null : objeto">
					{{objeto.description}}
				</div> 
				<span>
					<va-button flat class="mr-1" color="verde"  icon="add"
						@click="update(aba.tipo == 'planos' ?  'plano_frequencia_item' : 'checklist_section_item', objeto)" size="small" />
					<va-button flat color="verde" icon="edit" @click="update(objeto)" size="small" />
				</span>
			</div>

			<div v-if="ativado && ativado.id == objeto.id" class="objeto-conteudo">
				<div v-if="this.aba.tipo == 'planos'">
					<div v-for="item in objeto.items" :key="item.id" class="sub-itens" >
						{{item.task_description}}
						<div style="width: 30%; display: flex; justify-content: space-between; ">
							<span><va-icon name='update' ></va-icon> {{item.frequency_day}} dia(s) </span>
							HH: {{item.hh}}
							<va-button flat color="verde" icon="edit" @click="update(item)" size="small" />
						</div> 
					</div>
				</div>
				<div v-if="this.aba.tipo == 'checklists'">
					<div v-for="item in objeto.items" :key="item.id" class="sub-itens" >
						{{item.description}}
						<va-button flat color="verde" icon="edit" @click="update(item)" size="small" />
					</div>
				</div>
			</div>
		</div>
	</div>
    </va-modal>
</template>

<script>
export default {
	name: "ModalDocumentos",
	data() {
		return {
			ativado: null
		}
	},
	methods: {
		update(objeto, parente={}){
			this.$store.dispatch('edit-modal.editShow', {
				objeto: objeto,
                callback: this.aba.callback,
				id_parente: parente.id
			})
		},
	},
	computed: {
		show: {
			get(){
				return this.$store.getters['documentos.getShow']
			},
			set(show){
				this.$store.dispatch('documentos.change_show', show)
			}
		},
		aba() {
			return this.$store.getters['documentos.getAba']
		},
		objetos() {
			return this.$store.getters['documentos.getObjetos']
		},
		/*columns(){
			if(this.aba.tipo == 'planos'){
				return [
					{ key: 'task_description' , label:'Descrição', sortable: true },
					{ key: 'frequency_day' , label:'Frequência', sortable: true },
					{ key: 'hh' , label:'HH', sortable: true },
					//{ key: 'actions', label: 'AÇÕES'}
				]
			}
			else if(this.aba.tipo == 'checklists'){
				return [
					{ key: 'description' , label:'Descrição', sortable: true },
					//{ key: 'actions', label: 'AÇÕES'}
				]
			}
			else return []
		},*/
	},
}
</script>

<style scoped>
.modal-documentos{
	color: white;
	min-width: 60vw;
	display: flex;
	flex-direction: column;
}
.modal-documentos-header{
	display: flex;
	justify-content: space-between;
}
.modal-documentos-objeto{
	margin-bottom: 10px;
}
.objeto-titulo{
	background-color: var(--c4);
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.objeto-titulo div {
	width:90%;
	padding: 10px 5px;
}
.objeto-titulo div:hover{
	cursor: pointer;
}
.sub-itens{
	background-color: var(--c1);
	/*margin-left: 20px;*/
	/*margin-top: 2px;*/
	/*padding: 5px;*/
	padding: 10px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.objeto-conteudo{
	padding: 0px;
}
</style>