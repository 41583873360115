import Report from '@/models/report'
import { url } from '@/configs/global'

export default {
    state: () => ({
        reports: [],
    }),
    mutations: {
        'reports.set': function(state, {reports}){
            if(reports != undefined) state.reports = reports
        },
    },
    getters: {
        'reports.get': function(state){
            return state.reports
        }
    },
    actions: {
        'reports.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_reports`)
			.then((response) => {
                commit('reports.set', {
                    reports: response.data.data.map(obj => new Report(obj, this.axios)).sort((a, b) => a.anomaly.localeCompare(b.anomaly))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}