<template>
<div class="system-topbar">
    <img class="logoflexxs" src="@/assets/logo_px40_clara.png"/>
    <div class="system-topbar-empresa">Workspace: {{getUserEmpresa.company_name}}</div>
    <div class="filtro" v-if="vIF" >
      <va-input v-if="showPesquisa" v-model="filtro" color="red" flat id="filtro"/>
      <va-button @click=changePesquisa() style="width: 40px;" color="secondary" flat size="medium" icon="search"></va-button>
		</div>
    <div id="system-topbar-rightside" style="display:flex; align-items: center; max-width: 270px">
      <SystemTopbarUser />
    </div>
</div>
</template>

<script>
import SystemTopbarUser from '@/components/System/SystemTopbarUser';
export default {
    name: 'SystemTitle',
    components: {SystemTopbarUser},
    data: () => ({
        showPesquisa: false,
		    filtro: ""
    }),
    computed: {
        getUserEmpresa(){
            return this.$store.getters['empresa.get']
        },
        getFiltro(){
          return this.$store.getters['abas.getFiltro'][this.$route.path]
        },
        getAbas(){
            return this.$store.getters['abas.getAbas'][this.$route.path]
        },
        vIF(){
            return this.getAbas != null && this.getAbas != undefined && this.getAbas.length > 0
        },
    },
    watch: {
        filtro(value){
            this.$store.dispatch('abas.setFiltro', [this.$route.path, value])
        },
        getFiltro(value){
            this.filtro = value
        }
    },
    methods:{
        changePesquisa(){
          this.showPesquisa = (this.showPesquisa ? false : true)
          return this.showPesquisa
        }
    }

}
</script>

<style scoped>
.system-topbar{
    grid-area: system-topbar;
    background-color: var(--c1);
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 100%;
}
.system-topbar-empresa{
    margin-left: 50px;
    color: var(--c3);
    font-weight: bold;
}

.logoflexxs{
    display: none;
}
.filtro {
	display: none;
}
.ativos-filter{
	width: 100%;
	background-color: blue !important;
  color: blue;
  
}


@media only screen and (max-width: 600px) {
  .system-topbar{
    width: 100vw;
  }
  .system-topbar-empresa {
    display: none;
  }
  .logoflexxs{
    width: 8em;
    padding: 10px;
    display: flex;
  }
  .filtro {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

</style>