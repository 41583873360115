<template>
   	<div class="vuestic-datetime">
		<va-date-input :color="color" v-model="date" :label="label" />
		<va-time-input :color="color" v-model="time" />
	</div>
</template>

<script>
export default {
    name: 'VaDatetime',
	props: ['modelValue', 'color', 'label', 'type'],
 	emits: ['update:modelValue'],
	data() {
		return {
			date: null,
			time: null
		}
	},
	methods: {
		handler(){
			if (!this.date || !this.time) return
			let t_date = parseInt(this.date.getTime()/(86400*1000))*86400 + 3*3600
			let t_time = parseInt(((this.time.getTime()-3*3600*1000) % (86400*1000))/1000)
			if (this.type == 'timestamp'){
				this.$emit('update:modelValue', t_date+t_time)
			}else if (this.type == 'string'){
				this.$emit('update:modelValue', this.format(new Date((t_date+t_time)*1000)) )
			}
		},
		format(date){
			const year = date.getFullYear()
			const month = date.getMonth()+1
			const day = date.getDate()
			const hour = date.getHours()
			const minutes = date.getMinutes()
			const seconds = date.getSeconds()
			return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hour < 10 ? '0' : ''}${hour}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
		}
	},
	watch: {
		date(){ this.handler() },
		time(){ this.handler() },
	},
	mounted(){
		if (this.modelValue && this.type == 'timestamp'){
			this.date = new Date(this.modelValue*1000)
			this.time = new Date(this.modelValue*1000)
		}else if (this.modelValue && this.type == 'string'){
			this.date = new Date(Date.parse(this.modelValue))
			this.time = new Date(Date.parse(this.modelValue))
		}else{
			this.handler()
		}
	}
}
</script>

<style scoped>
.vuestic-datetime{
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>