import { url, model } from '@/configs/global'
import Usuario from './usuario'

class Empresa extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }

        this.axios = axios
        this.model_type = 'empresa'
        this.listaCriacao = [
            {label: 'company_name', type: 'text', labelInput: 'Nome do Workspace'},
        ]
        this.listaAtualizacao = [
            ...this.listaCriacao,
            {label: 'cnpj', type: 'text', labelInput: 'CNPJ'},
        ]
    }

    async getUsuarios(){
        try {
            return (await this.axios.get(`${url}/membro?id_e=${this.ID}`)).data.map(obj => new Usuario(obj, this.axios))
        } catch (error) {
            console.log(error)
            return []
        }
        
    }

    async create(){
        const obj = this.filterCreate()
        return await this.axios.post(`${url}/api/save_company`, obj)
    }

    async update(){
        const obj = this.filterUpdate()
        return await this.axios.put(`${url}/api/${this.id}/update_company`, obj)
    }

    getImageURL(){
        return `https://px40-api.cme-eng.com.br/thumb/companies/${this.url_image}?c=${this.id}&h=250&w=250`
    }

    async savePhoto({getters, blob}){

        const formData = new FormData()
        formData.append('file', blob)
        formData.append('company_id', this.id)
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_company_image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }
}

export default Empresa