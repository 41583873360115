<template>
   	<div class="empresa-default-image" >
		<va-icon name="image" color="#000"></va-icon>
	</div>
</template>

<script>
export default {
    name: 'EmpresaImageDefault'
}
</script>

<style scoped>
.empresa-default-image{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
</style>