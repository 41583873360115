import { url, model } from '@/configs/global'
import PlanoFrequencia from './plano_frequencia'
import PlanoFrequenciaItem from './plano_frequencia_item'

class Plano extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.model_type = 'plano'
        this.allowRemove = true

        this.listaCriacao = [
            {label: 'description', type: 'text', labelInput: 'Nome'},
            {label: 'type', type: 'select', textBy: 'label', valueBy: 'id', trackBy: 'id', labelInput: 'Tipo',
            options: () => [{'id': 0, 'label': 'Manut.'}, {'id': 1, 'label': 'Oper.'}, {'id': 2, 'label': 'Cont.'}]},
            {label: 'public', type: 'select', textBy: 'label', valueBy: 'id', trackBy: 'id', labelInput: 'Privacidade',
            options: () => [{'id': 0, 'label': 'Privado'}, {'id': 1, 'label': 'Público'}]},
        ]
        this.listaAtualizacao = this.listaCriacao
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_plan`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        return await this.axios.put(`${url}/api/${id_e}/update_plan/${this.id}`, obj)
    }

    async delete({getters}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_plan/${this.id}`)
    }

    async getChildren({getters}){
        
        const id_e = getters['empresa.get'].id
        const res = await this.axios.get(`${url}/api/${id_e}/get_plan/${this.id}`)
        const children = res.data.data.frequencies.map((obj)=>{
            return new PlanoFrequencia({
                ...obj,
                items: obj.tasks.map(task => new PlanoFrequenciaItem(task, this.axios))
            }, this.axios)
        })
        console.log(children)
        return children
    }
}

export default Plano