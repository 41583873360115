import { url, model } from '@/configs/global'
import ChecklistSection from './checklist_section'
import ChecklistSectionItem from './checklist_section_item'

class Checklist extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.model_type = 'checklist'
        this.allowRemove = true

        this.listaCriacao = [
            {label: 'description', type: 'text', labelInput: 'Nome'},
            {label: 'public', type: 'select', textBy: 'label', valueBy: 'id', trackBy: 'id', labelInput: 'Privacidade',
            options: () => [{'id': 0, 'label': 'Privado'}, {'id': 1, 'label': 'Público'}]},
        ]
        this.listaAtualizacao = this.listaCriacao
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_checklist`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        return await this.axios.put(`${url}/api/${id_e}/update_checklist/${this.id}`, obj)
    }

    async delete({getters}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_checklist/${this.id}`)
    }

    async getChildren({getters}){
        
        const id_e = getters['empresa.get'].id
        const res = await this.axios.get(`${url}/api/${id_e}/get_checklist_section/${this.id}`)
        const children = await Promise.all(res.data.data.map(async(obj)=>{
            const res_items = await this.axios.get(`${url}/api/${id_e}/get_checklist_item/${obj.id}`)
            obj.items = res_items.data.data.map(e => new ChecklistSectionItem(e, this.axios))
            return new ChecklistSection(obj, this.axios)
        }))
        return children
    }
}

export default Checklist