module.exports = {
    'url': "https://px40-api.cme-eng.com.br",
	'color': ["#f8cb70", "#f85b40", "#4ea397",'#5470c6', '#91cc75', '#a60303', '#73c0de','#9a60b4', '#ea7ccc'],
    'model': class {

        filter(lista){
            return Object.keys(this).filter((key) => lista.includes(key))
                .reduce((cur, key) => { return Object.assign(cur, { [key]: this[key] })}, {});
        }

        filterCreate(){
            let lista = this.listaCriacao.map(e=>e.label)
            if(this.concat) lista = lista.concat(Object.values(this.concat))
            return this.filter(lista)
        }

        filterUpdate(){
            let lista = this.listaAtualizacao.map(e=>e.label)
            if(this.concatUpdate) lista = lista.concat(Object.values(this.concatUpdate))
            return this.filter(lista)
        }
    }
}