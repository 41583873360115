<template>
<div class="system-menu-button" @click="navigate(route)" :style="$route.path == route ? 'background-color: rgba(0, 0, 0, 0.1);' : ''">
    <fa      v-if="icon != undefined && (type == 'fa'||type == undefined)" :icon="icon" class="system-menu-button-icon" />
    <va-icon v-if="icon != undefined && type == 'vuestic'" :name="icon" class="system-menu-button-icon" size="1.5rem" />
    <span class="system-menu-button-title">{{title}}</span>
</div>
</template>

<script>
export default {
    name: 'SystemMenuButton',
    props:{
        title: String,
        icon: String,
        route: String,
        type: String,
    },
    computed:{
        getEmpresa(){
            return this.$store.getters['empresa.get'].ID
        }
    },
    methods: {
        navigate(route){
            if (this.$route.path != route){
                this.$router.push({path: route})
            }
		},
    }
}
</script>

<style>
.system-menu-button{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--c3);
    padding: 10px 5px;
}
.system-menu-button:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}
.system-menu-button-title{
    font-size: 0.7rem;
    margin-top: 10px;
}
.system-menu-button-icon{
    font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
    .system-menu-button{
        padding: 2px 2px;
    }
    .system-menu-button-title{
        font-size: 0.5rem;
    }
    .system-menu-button-icon{
        font-size: 1.07rem;
    }
}
</style>