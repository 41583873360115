import { url, model } from '@/configs/global'

class Servico extends model{
    constructor(data, axios) {
        super()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                this[key] = data[key]
            }
        }
        this.axios = axios
        this.solicitacao = this.request
        this.model_type = 'servico'
        this.allowRemove = true

        this.listaCriacao = [
            {label: 'solicitacao', type: 'text', labelInput: 'Descrição / Solicitação'},
            {label: 'ativo_id', type: 'select', textBy: 'description', valueBy: 'id', trackBy: 'id', labelInput: 'Ativo',
            options: ({getters}) => {
                return getters['ativos.get']
            }},
            {label: 'dt_start_scheduled', type: 'datetime', labelInput: 'Início'},
            {label: 'dt_end_scheduled', type: 'datetime', labelInput: 'Fim'},
        ]
        this.listaAtualizacao = [
            ...this.listaCriacao,
            {label: 'finish', type: 'select', textBy: 'label', valueBy: 'id', trackBy: 'id', labelInput: 'Situação',
            options: () => [{'id': true, 'label': 'Finalizado'}, {'id': false, 'label': 'Não finalizado'}]},
        ]
    }

    async create({getters}){
        const obj = this.filterCreate()
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_service`, obj)
    }

    async update({getters}){
        const obj = this.filterUpdate()
        const id_e = getters['empresa.get'].id
        console.log(obj)
        return await this.axios.patch(`${url}/api/${id_e}/update_service/${this.id}`, obj)
    }

    async delete({getters}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_service/${this.id}`)
    }

    // Evidencias

    async getAllPhotos({getters}){
        const id_e = getters['empresa.get'].id
        const res = await this.axios.get(`${url}/api/${id_e}/get_service_photos/${this.id}`)
        return res.data.data.map((obj)=>{
            return {
                ...obj,
                url: `https://px40-api.cme-eng.com.br/thumb/services/${obj.img}?c=${id_e}&s=${this.id}&h=250&w=250`
            }
        })
    }

    async deletePhoto({getters, id_image}){
        const id_e = getters['empresa.get'].id
        return await this.axios.delete(`${url}/api/${id_e}/delete_service_image/${id_image}`)
    }

    async savePhoto({getters, blob}){

        const formData = new FormData()
        formData.append('file', blob)
        formData.append('service_id', this.id)
        formData.append('order', 1)
        const id_e = getters['empresa.get'].id
        return await this.axios.post(`${url}/api/${id_e}/save_service_image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }
}

export default Servico