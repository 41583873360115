<template>
    <va-modal v-model="show" title="Login" :mobile-fullscreen="false" @close="close" :hide-default-actions="true" ok-text="ENTRAR" bordered >
    <va-inner-loading icon="autorenew" :loading="getLoading" class="index-login">
		 <va-input v-if="createNew" color="cinza" type="text" class="mb-3" label="Nome" v-model="NOME" :rules="[(v) => v.length > 3 || 'Deve conter no mínimo 3 letras']" @keyup.enter="confirm"></va-input>
        <va-input color="cinza" type="email" class="mb-3" label="Email" v-model="USUARIO" :rules="[(v) => (v.includes('@') && v.includes('.') ) || 'Insira um email válido.']" ></va-input>
        <va-input color="cinza" type="password" class="mb-3" label="Senha" v-model="SENHA"  @keyup.enter="confirm" :rules="[(v) => (v.length >= 6 || createNew == false) || 'Deve conter no mínimo 6 caracteres']" ></va-input>
        <va-input v-if="createNew" 
            :success="CONFIRMACAO != '' && CONFIRMACAO == SENHA" :error="CONFIRMACAO != '' && CONFIRMACAO != SENHA"
            error-messages='Senhas não coindicem.'
            color="cinza" type="password" class="mb-3" label="Confirmar senha" v-model="CONFIRMACAO"  @keyup.enter="confirm"></va-input>
       
        
        <va-button v-if="!createNew" :rounded="false" color="verde" text-color="cinza" class="index-login-button" @click="confirm">ENTRAR</va-button>
        <va-button v-else :rounded="false" color="verde" text-color="cinza" class="index-login-button" @click="register">CRIAR</va-button>
        <va-button v-if="createNew" :rounded="false" color="laranja" text-color="cinza" class="index-login-button mt-3" @click="createNew=false">CANCELAR</va-button>
        
        <div v-if="!createNew" class="index-login-recuperar">
            <span style="color: var(--branco)"> Não é cadastrado? </span> <span @click="createNew = true">Inscreva-se.</span>
        </div>
        <div v-if="!createNew && 0" class="index-login-recuperar" @click="showRecuperacao = true"> Esqueci minha senha. </div>
        <div class="index-recuperacao" v-if="showRecuperacao">
            <div class="index-recuperacao-titulo">Método de Recuperação de Senha:</div>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <va-button style="width: 40%; " :rounded="false" color="verde" text-color="cinza" @click="recuperar('TELEGRAM')">TELEGRAM</va-button>
                <va-button style="width: 40%;" :rounded="false" color="verde" text-color="cinza" @click="recuperar('EMAIL')">EMAIL</va-button>
            </div>
        </div>
  	</va-inner-loading>
    </va-modal>
   
</template>

<script>
export default {
    name: 'IndexLogin',
    data: () => ({
        show: false,
        USUARIO: "",
        SENHA: "",
        CONFIRMACAO: "",
        NOME: "",
        createNew: false,
        showRecuperacao: false,
    }),
    methods: {
        confirm() {
            this.$store.dispatch('login.confirm', {
                USUARIO: this.USUARIO,
                SENHA: this.SENHA
            });            
        },
        recuperar(METODO) {
            if (confirm('Confirmar?')){
                this.$store.dispatch('login.recuperar', {USUARIO: this.USUARIO, METODO: METODO})
                this.showRecuperacao = false
            }  
        },
        close() {
            this.$store.dispatch('login.close');
        },
        async register(){
            this.$store.dispatch('login.register', {USUARIO: this.USUARIO, SENHA: this.SENHA, NOME: this.NOME, CONFIRMACAO: this.CONFIRMACAO});
        }
    },
    computed: {
        getShowLogin(){
            return this.$store.getters['login.get'].show
        },
        getLoading(){
            return this.$store.getters['login.getLoading']
        }
    },
    watch: {
        show: function (value) {
            this.$store.dispatch('login.change', value);
        },
        getShowLogin: function(value){
            this.show = value
            this.showRecuperacao = false
            this.createNew = false
        }
    }
}
</script>

<style scoped>
.index-login{
    width: 400px;
}
.index-login-button{
    width: 100%;
    color:blue;
}
.index-login-recuperar{
    font-size: 0.9em;
    text-align: center;
    color: var(--azul);
    padding: 0.1em;
    margin: 0.9em 0;
}
.index-login-recuperar:hover{
    cursor: pointer;
    color: rgba(50, 153, 218, 0.5);
}
.index-recuperacao{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--c3);
    padding: 10px;
    padding-bottom: 0px;
    background-color: var(--c3);
    border-radius: 5px;
}
.index-recuperacao * {
    margin-bottom: 10px;
}
.index-recuperacao-titulo {
    color: var(--c1);
}
</style>