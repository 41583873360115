<template>
    <img :src="require('@/assets/' + name)" alt="" class="index-solution-img">
</template>

<script>
export default {
    name: 'IndexSolutionImage',
    props:{
        name: String,
    },
    components: { },
}
</script>

<style scoped>
.index-solution-img{
    width: 70%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
}


@media only screen and (max-width: 600px) {
    .index-solution-img{
    width: 80%;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    padding: 1em;
}
}
</style>