<template>
	
	<div class="empresa-info">
		<div style="display:flex; flex-direction: row">
			<va-input v-model="empresa.company_name" :style="'margin: 10px'" color="cinza" label="RAZÃO SOCIAL" type="name"/>
			<va-input v-model="empresa.CNPJ" :style="'margin: 10px; color: red'" color="cinza" label="CNPJ" placeholder="XX.XXX.XXX/0001-XX"/>
		</div>
			<va-button color="verde" text-color="cinza" class="mr-3" @click="edit()" >Atualizar</va-button>
	</div>

	<div class="empresa-imagecard">
		<div class="empresa-imagecard-file-upload">
			<va-file-upload color="verde" v-model="file" type="single" @input="input" file-types=".jpg,.png,.svg,.eps,.PNG,.jpeg" dropzone />
		</div>
		<img v-if="getEmpresa.url_image" :src="getEmpresa.getImageURL()" @click="showImg(getEmpresa.getImageURL())" alt="" class="empresa-imagecard-img">
		<va-modal v-model="show_upload" :mobile-fullscreen="false" color="verde"  @ok="enviar" ok-text="Enviar" cancel-text="Cancelar">
		</va-modal>
	</div>
	
</template>

<script>
//import {url} from '../../configs/global'
import Usuario from '../../models/usuario'
export default {
	name: 'EmpresaInfo',
	data: () => ({
		empresa: {},
		file: [],
		show_upload: false
    }),
    computed: {
        getEmpresa(){
			return this.$store.getters['empresa.get']
		}
    },
	watch: {
		getEmpresa(value){
			this.empresa = value
		}
	},
	methods:{
		load(){
			this.empresa = this.getEmpresa
		},
		edit(){
			this.$store.dispatch('loading.show')
			this.empresa.update({
				getters: this.$store.getters
			}).then(async () => {
				this.$store.dispatch('toast.success', 'Atualizado!')
				await this.getPhotos()
			}).catch(function (error) {
				console.log(error)
				this.$store.dispatch('toast.error', 'Erro!')
			}).finally(()=>this.$store.dispatch('loading.hide'))
		},
		showImg(img){
			window.open(img, "_blank");	
		},
		input: function(){
			this.show_upload = true
		},
		enviar: function() {
			this.empresa.savePhoto({
				getters: this.$store.getters,
				blob: this.file[0]
			}).then(async () => {
				this.file = []
				this.$store.dispatch('toast.success', 'Atualizado!')
				this.$store.dispatch('empresa.set', await Usuario.getAllCompanies(this.axios));
			}).catch((err)=>{
				console.log(err, err.data)
				this.$store.dispatch('toast.error', 'Erro!');
			})
		},
	},
	mounted() {
		this.load()
		console.log(this.getEmpresa)
	},
}
</script>

<style scoped>

.empresa-info {
	margin-bottom: 10px;
	background-color: var(--c4);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	grid-template-rows: 50% 50% ;
    grid-template-columns: 50% 50%;
}

.empresa-imagecard {
	margin-bottom: 10px;
	background-color: var(--c4);
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: var(--c3);
}

.empresa-imagecard-img{
	width: 20%;
}
.empresa-imagecard-name{
	align-self: flex-end;
}

.empresa-imagecard-file-upload{
	width: 50%;
	height: 100%;
	overflow-x: hidden;
	z-index: 0;
}

@media only screen and (max-width: 600px) {
	.empresa-info {
		margin-right: 0px;
		flex-direction: column;
		padding: 5px;
	}
	.empresa-imagecard{
		flex-direction: column;
	}
	.empresa-imagecard-img{
		margin-right: 10px;
		width: 60%;
	}
	.empresa-imagecard{
		margin-right: 0px;
	}
}

</style>