import { url } from '@/configs/global'

export default {
    state: () => ({
        CATEGORIAS: {},
    }),
    mutations: {
        'categoria.set': function(state, {key, value}){
            state.CATEGORIAS[key] = value
        }
    },
    getters: {
        'categorias.get': function(state){
            return state.CATEGORIAS
        }
    },
    actions: {
        'categorias.load': function( { dispatch, commit}, key){
            dispatch('loading.show');
			this.axios.get(`${url}/api/get_categories/${key}`)
			.then((response) => {
                commit('categoria.set', { key, value: response.data.data })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				dispatch('loading.hide');
			})
        }
    }
}