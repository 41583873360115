import Setor from '@/models/setor'
import { url } from '@/configs/global'

export default {
    state: () => ({
        setores: [],
    }),
    mutations: {
        'setores.set': function(state, {setores}){
            if(setores != undefined) state.setores = setores
        },
    },
    getters: {
        'setores.get': function(state){
            return state.setores
        }
    },
    actions: {
        'setores.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_company_sector`)
			.then((response) => {
                commit('setores.set', {
                    setores: response.data.data.map(obj => new Setor(obj, this.axios)).sort((a, b) => a.description.localeCompare(b.description))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}