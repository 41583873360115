import Ativo from '@/models/ativo'
import { url } from '@/configs/global'

export default {
    state: () => ({
        ativos: [],
    }),
    mutations: {
        'ativos.set': function(state, {ativos}){
            if(ativos != undefined) state.ativos = ativos
        },
    },
    getters: {
        'ativos.get': function(state){
            return state.ativos
        }
    },
    actions: {
        'ativos.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_ativos_company`)
			.then((response) => {
                commit('ativos.set', {
                    ativos: response.data.data.data.map(obj => new Ativo(obj, this.axios)).sort((a, b) => a.description.localeCompare(b.description))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}