<template>
   <div class="empresa-card">
	<div class="empresa-card-title">{{nome}}</div>
	<va-divider/>
	<div class="empresa-card-content">
	<va-icon :name="getIcon(nome)" size="1.8rem"/> 
	<div style="display:flex; flex-direction: column; aling-self: center;">{{numero}} 
		<span style="font-size: 0.5em; "> unidades</span>
	</div>
	</div>
   </div>
</template>

<script>
export default {
    name: 'EmpresaEstatisticaCard',
	props : ['icon', 'nome', 'numero'],
	computed: {
	},
	methods:{
		getIcon(categoria){
			if(categoria == 'Interno') return "people_alt"
			if(categoria == 'Externo/Convidado') return "group_add"
		},
	}
}
</script>

<style scoped>
.empresa-card {
	margin: 10px;
	width: 150px;
	height: 60%;
	background-color: var(--c4);
	color: var(--c3);
}

.empresa-card-title {
	color: var(--branco);
	padding: 5px;
	padding-bottom: 0px;
	font-size: 1em;
	width: 100%;
}
.empresa-card-content {
	color: var(--c3);
	display: flex;
	flex-direction:row;
	justify-content: space-around;
	font-size: 1.5em;
}

.empresa-card-icon{
	width: 10px;
}

</style>