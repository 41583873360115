import Empresa from "@/models/empresa";
import { url } from '@/configs/global'

export default {
    state: () => ({
        EMPRESAS: [],
        INDEX_EMPRESA: null,
    }),
    mutations: {
        'empresa.set': function(state, {EMPRESAS, INDEX_EMPRESA}){
            if (EMPRESAS != undefined)         state.EMPRESAS  = EMPRESAS
            if (INDEX_EMPRESA != undefined)    state.INDEX_EMPRESA   = INDEX_EMPRESA
        }
    },
    getters: {
        'empresa.get': function(state){
            if (state.INDEX_EMPRESA == null) return {}
            const empresas = state.EMPRESAS.filter((e)=>e.id == state.INDEX_EMPRESA)
            if (empresas.length > 0){
                return empresas[0]
            }else{
                return {}
            }
        },
        'empresas.get': function(state){
            return state.EMPRESAS
        }
    },
    actions: {
        'empresa.set': function( { commit }, EMPRESAS ){
            commit( 'empresa.set', {EMPRESAS: EMPRESAS.map(obj => new Empresa(obj.company, this.axios)).sort((a, b) => a.company_name.localeCompare(b.company_name))});
        },
        'empresa.select': function( { commit }, id_empresa ){
            commit( 'empresa.set', {
                INDEX_EMPRESA: id_empresa
            } );
            localStorage.setItem('id_empresa', id_empresa)
            if (id_empresa == null){
                localStorage.removeItem('id_empresa')
            }
            
        },
        'empresa.remove': function({dispatch, getters, commit}){
            if(prompt('Digite CONFIRMAR:') == 'CONFIRMAR' && 1 == 0){
                const id_e = getters['empresa.get'].id
                dispatch('loading.show')
                this.axios.delete(`${url}/api/${id_e}/delete_company`)
                .then(()=>{
                    dispatch('toast.success', 'Removido!')
                    commit( 'empresa.set', { EMPRESAS: getters['empresas.get'].filter((e)=>e.id != id_e) } );
                })
                .catch(()=>dispatch('toast.error', 'Erro!'))
                .finally(()=>{
                    this.$router.push('/bemvindo')
                    dispatch('loading.hide')
                })
            }else{
                dispatch('toast.error', 'Incorreto!')
            }
        }
            
    }
}