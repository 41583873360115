//import { url } from '@/configs/global'

export default {
    state: () => ({
        relatorios: [],
    }),
    mutations: {
        'relatorios.set': function(state, {relatorios}){
            if(relatorios != undefined) state.relatorios = relatorios
        },
    },
    getters: {
        'relatorios.get': function(state){
            return state.relatorios
        }
    },
    actions: {
        'relatorios.load': function( { dispatch, commit}){
            dispatch('loading.show');
			commit('relatorios.set', {
                relatorios: [
                    {nome: 'Inventário de Ativos', },
                    {nome: 'Relatório de Serviços', },
                    {nome: 'Relatório de Reports', },
                ]
            })
			setTimeout(() => {
                dispatch('loading.hide');
            }, 300);
        }
    }
}