import { createStore } from 'vuex'

import store_empresa from './empresa'

import store_loading from './loading'

import store_login from './login'

import store_toast from './toast'

import store_usuario from './usuario'

import store_ativos from './ativos'

import store_reports from './reports'

import store_servicos from './servicos'

import store_edit_modal from './edit-modal'

import store_categorias from './categorias'

import store_setores from './setores'

import store_projetos from './projetos'

import store_membros from './membros'

import store_mantenedores from './mantenedores'

import store_abas from './abas'

import store_evidencias from './evidencias'

import store_planos from './planos'

import store_checklists from './checklists'

import store_relatorios from './relatorios'

import store_documentos from './documentos'

const store = createStore({
    modules: {
        store_empresa,
        store_loading,
        store_login,
        store_toast,
        store_usuario,
        store_ativos,
        store_edit_modal,
        store_abas,
        store_reports,
        store_servicos,
        store_categorias,
        store_setores,
        store_projetos,
        store_membros,
        store_mantenedores,
        store_evidencias,
        store_planos,
        store_checklists,
        store_relatorios,
        store_documentos
    }
})

export default store