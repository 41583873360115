<template>
   <div class="patrimonio-card">
	<div class="patrimonio-card-title">{{nome}}</div>
	<va-divider/>
	<div class="patrimonio-card-content">
	<va-icon :name="getIcon(nome)" size="1.8rem" :color="getColor(nome)"/> 
	<div style="display:flex; flex-direction: column; aling-self: center;">{{numero}} 
		<span style="font-size: 0.5em; "> unidades</span>
	</div>
	</div>
   </div>
</template>

<script>
export default {
    name: 'PatrimonioEstatisticaCard',
	props : ['icon', 'nome', 'numero'],
	computed: {
	},
	methods:{
		getIcon(categoria){
			if(categoria == "Máquinas") return "precision_manufacturing"
			if(categoria == "Imóveis") return "home"
			if(categoria == "Veículos") return  "commute"
			if(categoria == "Móveis") return "chair"
			if(categoria == "Equipamentos") return "devices"
			if(categoria == "Componentes") return "construction"
			if(categoria == "Quadros Elétricos") return "ev_station"
			if(categoria == "Intalações") return "business"
			if(categoria == "Outros") return "format_list_bulleted"
			if(categoria == "Urgente") return "report"
			if(categoria == "Importante") return "report"
			if(categoria == "Média") return "report"
			if(categoria == "Baixa") return "report"
			if(categoria == "Concluídos") return "check_circle"
			if(categoria == "Em aberto") return "cancel"
		},
		getColor(categoria){
			if(categoria == "Urgente") return "#ff0a0a"
			if(categoria == "Importante") return "#ff640a"
			if(categoria == "Média") return "warning"
			if(categoria == "Baixa") return "success"
			if(categoria == "Concluídos") return "success"
			if(categoria == "Em aberto") return "danger"
			else return "cinza"
		}
	}
}
</script>

<style scoped>
.patrimonio-card {
	margin: 10px;
	width: 150px;
	height: 60%;
	background-color: var(--c4);
	color: var(--c3);
}

.patrimonio-card-title {
	color: var(--branco);
	padding: 5px;
	padding-bottom: 0px;
	font-size: 1em;
	width: 100%;
}
.patrimonio-card-content {
	color: var(--c3);
	display: flex;
	flex-direction:row;
	justify-content: space-around;
	font-size: 1.5em;
}

.patrimonio-card-icon{
	width: 10px;
}

</style>