<template>
    <va-modal v-model="showModal" no-dismiss="false" :mobile-fullscreen="false" :hide-default-actions="true" overlay-opacity="0">
        <div class="workspaces-modal1">
            <div class="workspaces-modal1-titulo">
                Bem vindo! Selecione o workspace:
            </div>
            <div v-for="(empresa) in empresas" :key="empresa.ID" class="workspaces-modal1-empresa" @click="select(empresa.id)">
                <div class="workspaces-modal1-empresa-text">{{empresa.company_name}}</div>
                <img v-if="empresa.url_image" :src="empresa.getImageURL()" alt="" class="workspaces-modal1-empresa-img" >
                <EmpresaImageDefault v-else style="width: 3rem; height: 3rem;" />
            </div>
            <div class="workspaces-modal-buttons">
                <va-button class="mt-3" color="azul" @click="criar">Criar um workspace</va-button> 
                <va-button class="mt-3" color="azul" @click="sair">Sair</va-button>
            </div>
        </div>
	</va-modal>
</template>

<script>
import EmpresaImageDefault from '../../utils/EmpresaImageDefault.vue'
export default {
    name: 'WorkspacesModal1',
    components: { EmpresaImageDefault },
    data: () => ({
        showModal: true,
    }),
    props:["empresas"],
	methods:{
		select(id_empresa){
            this.$store.dispatch('empresa.select', id_empresa);
            this.$router.push('/sistema/patrimonio');
		},
        sair(){
            this.$store.dispatch('login.logout');
            this.$router.push('/');
		},
        criar(){
            this.$store.dispatch('edit-modal.editShow', {
				objeto: 'empresa',
                callback: 'usuario.validate'
			})
        }
	}
}
</script>

<style scoped>
.workspaces-modal1{
    display: flex;
    flex-direction: column;
    color: var(--branco);
}
.workspaces-modal1-empresa{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    padding: 5px;
    background-color: var(--verde);
    min-width: 500px;
}
.workspaces-modal1-empresa:hover{
    cursor: pointer;
    background-color: var(--verde_hover);
}
.workspaces-modal1-empresa-img {
    width: 3rem;
    height: 3rem;
}
.workspaces-modal1-empresa-text {
    color: var(--cinza);
}
.workspaces-modal1-titulo{
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
}
</style>