<template>
	<span class="versao-web">
		<va-inner-loading v-if="!loading" icon="autorenew" :loading="getLoading">
			<router-view class="app-view"></router-view>
  		</va-inner-loading>
		<div v-else class="app-loading">
			<img src="@/assets/logo_px40_clara_quadrada.png" alt="" srcset=""><va-icon name="autorenew" class="mt-3" color="#3CDE93" spin/>
		</div>
		<EditModal/>
		<ModalEvidencias/>
	</span>
	<div class="versao-mobile">
        <img class="logopx40" src="@/assets/logo_px40_clara.png"/>
		<label class="mb-1">Acesse a versão mobile:</label>
		<a href="http://px40.cme-eng.com.br" style="color: white; text-decoration: underline;">http://px40.cme-eng.com.br</a>
	</div>
</template>

<script>
import EditModal from './utils/EditModal.vue'
import ModalEvidencias from './utils/ModalEvidencias.vue'
export default {
	name: 'App',
	components: {EditModal, ModalEvidencias},
	computed:{
		loading(){
			return this.$store.getters['usuario.getLoading']
		},
        getLoading(){
            return this.$store.getters['loading.get']
        },
	},
	created(){
		this.$store.dispatch('usuario.validate');
	}
}
</script>

<style>
body, html {
	margin: 0;
	padding: 0;
	height: 100;
	font-family: 'poppins', sans-serif;
}
.app-view {
	margin: 0;
	height: 100vh;
	width: 100vw;
}
.app-loading{
	margin: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #32353E;
}
.app-loading img {
	width:7rem;
}
:root {
	--c1: #3C3F4A; 
	--cinza: #32353E; 
	--c3: #CCCCCC;
	--c4: #525766;
	--c5: #636363;
	--verde: #3CDE93;
	--azul: #3CB3DE;
	--verde_hover: #3CDE93ff;
	--laranja: #F85B40;
	--amarelo: #FFDDAA;
	--branco: #FEFFFA;
}

.va-modal__inner {
	background-color: var(--cinza) !important;
}

.va-modal__title {
	color: var(--branco) !important;
	font-size: 1em !important;
	text-align: center;
}

.va-data-table__table-th-wrapper{
	color:var(--c3) !important;
}

.va-pagination__input{
	background-color: var(--c4) !important;
}

/*
.va-input{
background-color: var(--c4) !important;
}
*/

.va-input__container {
	background-color: var(--c3) !important;
	color: var(--branco) !important;
}

.va-input__content__input {
	color: var(--cinza) !important;
}


.va-dropdown__content-wrapper{
	width:280px !important;
	color: var(--c1) !important;
}

.va-modal__dialog {
	max-height: 82vh !important;
	box-shadow: none !important;
}
.versao-mobile{
	display: none;
}
@media only screen and (max-width: 600px) {
	.versao-mobile{
		display: flex;
		align-items: center;
		flex-direction: column;
		background-color: #32353E;
		color: white;
		height: 100vh;
		padding: 50px 0px;
	}
	.logopx40{
		width: 30%;
		margin-bottom: 50px;
	}
	.versao-web{
		display: none;
	}
}
</style>