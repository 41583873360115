<template>
<div class="system-menu">
    <div class="system-menu-buttons">
        <SystemMenuImage/>
        <SystemMenuButton title="Patrimônio" icon="tablet_mac" type="vuestic" route='/sistema/patrimonio'/>
        <SystemMenuButton title="Documentos" icon="description" type="vuestic" route='/sistema/documentos'/>
        <SystemMenuButton title="Empresa" icon="factory" type="vuestic" route='/sistema/empresa'/>
    </div>
    <img src="@/assets/logo_px40_clara.png" alt="" class="system-menu-logoflexxs">
</div>
</template>

<script>
import SystemMenuButton from './SystemMenuButton'
import SystemMenuImage from './SystemMenuImage'
export default {
    name: 'SystemMenu',
    components:{SystemMenuButton, SystemMenuImage},
    computed:{

    }
}
</script>

<style scoped>
.system-menu{
    grid-area: system-menu;
    background-color: var(--c1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.system-menu-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.system-menu-logoflexxs{
    width: 90%;
    margin-bottom: 20px;
    display: flex;
}
.hline{
    display: none;
    border-bottom: 1px solid var(--c3);
    margin: 1px 0;
    width: 90%;
}


@media only screen and (max-width: 600px) {
    .system-menu{
        flex-direction: row;
        width: 100vw;
    }
    .hline{
        display: none;
    }
}
</style>