import Checklist from '@/models/checklist'
import { url } from '@/configs/global'

export default {
    state: () => ({
        checklists: [],
    }),
    mutations: {
        'checklists.set': function(state, {checklists}){
            if(checklists != undefined) state.checklists = checklists
        },
    },
    getters: {
        'checklists.get': function(state){
            return state.checklists
        }
    },
    actions: {
        'checklists.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_checklist`)
			.then((response) => {
                commit('checklists.set', {
                    checklists: response.data.data.data.map(obj => new Checklist(obj, this.axios)).sort((a, b) => a.description.localeCompare(b.description))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}