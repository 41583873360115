export default {
    state: () => ({
        loading: false
    }),
    mutations: {
        'loading.set': function(state, loading){
            state.loading = loading
        }
    },
    getters: {
        'loading.get': function(state){
            return state.loading
        }
    },
    actions: {
        'loading.show': function( { commit }){
            commit( 'loading.set', true );
        },
        'loading.hide': function( { commit }){
            commit( 'loading.set', false );
        }
    }
}