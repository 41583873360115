<template>
    <div class="index-title-button">
        {{title}}
    </div>
</template>

<script>
export default {
    name: 'IndexTitleButton',
    props:{
        title: String
    },
}
</script>

<style scoped>
.index-title-button{
    padding: 15px;
    margin: 0 10px;
    font-size: 1.3em;
    border-radius: 7px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--branco);
}
.index-title-button:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}


@media only screen and (max-width: 600px) {
    .index-title-button{
        width: 70vw;
        background-color: var(--verde);
        /*margin: 50px 10px;*/
        align-content: center;
        color: #E1E2E5;
    }
}
</style>