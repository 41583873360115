<template>
    <div class="index-solutions">
        <div class="index-solutions-title">Soluções</div>
        <div class="index-solutions-quadros">
            <IndexSolutionImage name= "colete.svg"/>
            <IndexSolutionCaption title="Gestão de Ativos" text="Registre todas as informações a cerca dos seus ativos e gerencie, através do aplicativo, todos os reports e oportunidades da sua base de ativos." />
            <IndexSolutionCaption title="Controle de Serviços" text="Compatilhe em tempo real o trabalho da sua equipe. Utilize as ferramentas de agendas, planos e registo de serviços para e garanta uma gestão mais simples e dinâmica. "/>
            <IndexSolutionImage  name= "analise.svg"/>
            <IndexSolutionImage  name= "tomedecisoes.svg"/>
            <IndexSolutionCaption title= "Visão Computacional" text= "Implantação e Consultoria PCM, Inspeção Termográfica, Manutenção em Equipamentos Eletroeletrônicos e muito mais"/>
        </div>
    </div>
</template>

<script>
import IndexSolutionImage from "@/components/Index/IndexSolutionImage"
import IndexSolutionCaption from "@/components/Index/IndexSolutionCaption"
export default {
    name: 'IndexSolutions', 
    components: {IndexSolutionImage, IndexSolutionCaption},
}
</script>

<style scoped>

.index-solutions{
    background-color: var(--c4);
    width: 100%;
    height: auto;
}
.index-solutions-quadros{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.index-solutions-title{
    width: 100%;
    height: auto;
    font-size: 2em;
    text-align: center;
    color: var(--azul);
    padding: 1em;
}

@media only screen and (max-width: 600px) {
  .index-solutions-quadros {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  }
}

</style>