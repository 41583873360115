<template>
   <div class="patrimonio-tabela">
	    <div class="titulo">
			{{aba.nome}}
		</div>
		<div class="tabela">
			<va-data-table hoverable :items="objetos" :columns="columns">
				<template v-if="aba.nome == 'Ativos'" #cell(category)="{ rowIndex }">
					{{objetos[rowIndex].category.description}}
				</template>
				<template v-if="aba.nome == 'Ativos'" #cell(coordinates)="{ rowIndex }">
					<va-button v-if="objetos[rowIndex].coordinates" color="secondary" flat icon="place" @click=maps(objetos[rowIndex].coordinates.latitude,objetos[rowIndex].coordinates.longitude) size="small" />
					<!--{{objetos[rowIndex].coordinates ? objetos[rowIndex].coordinates.latitude : ''}}
					{{objetos[rowIndex].coordinates ? objetos[rowIndex].coordinates.longitude : ''}}-->
				</template>
				<template v-if="aba.nome == 'Reports' || aba.nome == 'Serviços'" #cell(ativo)="{ rowIndex }">
					{{objetos[rowIndex].ativo ? objetos[rowIndex].ativo.description : ''}}
				</template>
				<template v-if="aba.nome == 'Reports'" #cell(created_at)="{ rowIndex }">
					{{timestamp(objetos[rowIndex].created_at)}}
				</template>
				<template v-if="aba.nome == 'Serviços'" #cell(responsible)="{ rowIndex }">
					{{objetos[rowIndex].responsible ? objetos[rowIndex].responsible.name: ''}}
				</template>
				<template v-if="aba.nome == 'Serviços'" #cell(finish)="{ rowIndex }">
					<va-icon size="medium" :name="objetos[rowIndex].finish == true ? 'done' : 'close'" :color="objetos[rowIndex].finish ? 'verde' : 'laranja'"/>
				</template>
				<template #cell(actions)="{ rowIndex }">
					<va-button color="secondary" flat icon="edit" @click="update(objetos[rowIndex])" size="small" />
					<va-button color="secondary" flat icon="image" @click="getAllPhotos(objetos[rowIndex])" size="small" />
					<!--<va-button color="secondary" flat icon="visibility" @click="update(objetos[rowIndex])" size="small" />-->
				</template>
			</va-data-table>
		</div>
	  	<!--<div v-for="objeto in objetos" :key="objeto.id">
			<div style="padding: 10px;">{{objeto}}</div>
		</div>-->
   </div>
</template>

<script>
export default {
    name: 'PatrimonioTabela',
	props : ['aba', 'caption', 'objetos'],
	computed: {
		columns(){
			if(this.aba.nome == 'Ativos'){
				return [
						{ key: 'description' , label:'DESCRIÇÃO', sortable: true },
						{ key: 'patrimony' , label: 'N° PATRIMONIO'},
						{ key: 'coordinates', label: 'LOCALIZAÇÃO'},
						{ key: 'category', label:'CATEGORIA'},
						{ key: 'actions', label: 'AÇÕES'}
						
				]
			}
			else if(this.aba.nome == 'Reports'){
				return [
						{ key: 'anomaly' , label:'ANOMALIA', sortable: true },
						{ key: 'ativo' , label: 'ATIVO'},
						{ key: 'criticality', label:'CRITICIDADE'},
						{ key: 'created_at', label:'CRIADO EM'},
						{ key: 'actions', label: 'AÇÕES'}
					]
			}
			else if(this.aba.nome == 'Serviços'){
				return [
						{ key: 'request' , label:'REQUISIÇÃO'},
						{ key: 'ativo' , label: 'ATIVO'},
						{ key: 'priority', label:'PRIORIDADE'},
						{ key: 'responsible', label:'RESPOSÁVEL'},
						{ key: 'dt_start_scheduled', label:'Início'},
						{ key: 'dt_end_scheduled', label:'Fim'},
						{ key: 'finish' , label:'STATUS'},
						{ key: 'actions', label: 'AÇÕES'}
					]
			}
			else return []
		}
	},
	methods:{
		update(objeto){
			this.$store.dispatch('edit-modal.editShow', {
				objeto: objeto,
                callback: this.aba.callback
			})
		},
		async getAllPhotos(objeto){
			this.$store.dispatch('evidencias.show', objeto)
		},
		timestamp(time_str){
			var date = new Date(time_str)
			return date.toLocaleDateString('pt-BR')
		},
		maps(latitude, longitude){
			if (latitude != null && latitude != undefined && longitude != null && longitude != undefined){
				const url = `https://www.google.com/maps/place/${latitude}${longitude}/@${latitude}${longitude}`
				window.open(url, "_blank");
			}
		}
	}
}
</script>

<style scoped>
.patrimonio-tabela {
  margin-top: 10px;
  grid-area: patrimonio-tabela;
  background-color: var(--c1);
}

.titulo{
	background-color: var(--c4);
	padding: 6px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--branco)
}
.tabela{
	color: var(--c3)
}
</style>