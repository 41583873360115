import Mantenedor from '@/models/mantenedor'
import { url } from '@/configs/global'

export default {
    state: () => ({
        mantenedores: [],
    }),
    mutations: {
        'mantenedores.set': function(state, {mantenedores}){
            if(mantenedores != undefined) state.mantenedores = mantenedores
        },
    },
    getters: {
        'mantenedores.get': function(state){
            return state.mantenedores
        }
    },
    actions: {
        'mantenedores.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_maintainer_by_company_id`)
			.then((response) => {
                commit('mantenedores.set', {
                    mantenedores: response.data.data.map(obj => new Mantenedor(obj, this.axios)).sort((a, b) => a.name.localeCompare(b.name))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}