<template>
    <div class="system-footer">
        <img class="logoflexxs" src="@/assets/logo_px40_clara.png"/>
        <span>Copyright 2022. Desenvolvido por flexxs iT.</span>
    </div>
</template>

<script>
export default {
    name: 'SystemFooter',
}
</script>

<style scoped>
.system-footer{
    grid-area: system-footer;
    background-color: var(--c1);
    color: var(--c3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
}
.logoflexxs{
    margin-right: 50px;
    height: 60%;
}

@media only screen and (max-width: 600px) {
    .logoflexxs{
        display:none;
    }
    .system-footer{
        display: none;
    }
}

</style>