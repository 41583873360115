<template>
    <div class="user-dropdown">
        <div class="user-dropdown-button" @click="show = !show"  >
            <div class="user-dropdown-button-user">{{getUserName}}</div>
            <img :src="getImageURL" alt="" class="d-none d-sm-block user-dropdown-button-img"  >
            <!-- Mobile -- -->
            <div class="topbar-button">
               <va-icon name="list" />
            </div>
            <!-- Mobile -- -->
        </div>
        <div class="user-dropdown-content" v-bind:id="show? 'content-show' : 'content-hide'">
            <div class="topbar-user">
                <div style="max-height: 50%; max-width:100%; padding-bottom:20px;">
                    <img :src="getImageURL" alt="" class="user-dropdown-button-img-mobile" @click.prevent="perfil">
                    <va-icon style="padding-bottom:20px; margin-top:0px;" name="edit" @click.prevent="perfil" color="c5"/>
                </div>
                <div>{{getUserName}}</div>
                <div style="color: var(--c5)">{{getUserEmail}}</div>
                <div style="color: var(--c5)"><va-divider />{{getUserEmpresa.company_name}}</div>
            </div>

            <a href @click.prevent="trocar">Trocar de workspace<va-icon name="change_circle" size="1.3rem"/></a>
            <a href @click.prevent="remover_workspace">Remover workspace<va-icon name="delete" size="1.3rem"/></a>
            <a href @click.prevent="logout">Sair <va-icon name="logout" size="1.3rem"/></a>
        </div>
    </div>
</template>

<script>
import { url } from '@/configs/global'
export default {
    name: 'SystemTopbarUser',
    components: {  },
    data() {
        return {
            show: false
        }
    },
    methods: {
        logout(){
            this.show = false
			this.$store.dispatch('login.logout')
        },
        perfil(){
            this.show = false
            if (this.$route.path != '/sistema/usuario') this.$router.push({path: '/sistema/usuario'});
        },
        trocar(){
            this.show = false
            this.$router.push('/bemvindo');
        },
        remover_workspace(){
            this.$store.dispatch('empresa.remove')
        }        
    },
    computed: {
        getUserName(){
            return this.$store.getters['usuario.get'].name
        },
        getUserEmail(){
            return this.$store.getters['usuario.get'].email
        },
		getImageURL(){
            let id = this.$store.getters['usuario.get'].ID
            if (id != null && id != undefined){
                return `${url}/usuario-figura?user_id=${id}`
            }else{
                return ""
            }
            
        },
        getUserEmpresa(){
            return this.$store.getters['empresa.get']
        }
    },
    mounted() {
        document.querySelector('.app-view').addEventListener('click', (e) => {
            if(! Array.from(document.querySelector('.user-dropdown').getElementsByTagName('*')).includes(e.target) ) {
                this.show = false
            }
        })
    },
}
</script>

<style>
    .user-dropdown {
        position: relative;
        height: 50px;
    }
    .topbar-button {
        display: none;
    }
    .user-dropdown-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        padding: 0 10px;
        height: 100%;
        color: var(--c3);
    }
    .user-dropdown-button-img{
        height: 80% !important;
        border-radius: 50px;
        margin-left: 10px;
        align-content: right;
    }
    .user-dropdown-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    .user-dropdown-content {
        position: absolute;
        right: 0px;
        background-color: var(--c4);
        min-width: 270px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); padding: 10px;
        z-index: 10;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        transition: visibility 0s, opacity 0.3s linear;
    }
    .perfil{
        display: flex;
        flex-direction: column;
    }
    #content-show {
        visibility: visible;
        opacity: 1;
    }
    #content-hide {
        visibility: hidden;
        opacity: 0;
    }
    .user-dropdown-content a {
        text-decoration: none;
        color: white;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .user-dropdown-content a:hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    .topbar-user{
        display: none;
    }
    

@media only screen and (max-width: 600px) {
    .user-dropdown-button {
        font-size: 0.8em;
    }
    .user-dropdown {
        width: 50px;
        height: 40px;
    }
    .topbar-button {
        display: flex;
        align-items: center;
    }
    .user-dropdown-button-user{
        display: none;
    }
    .user-dropdown-button-img{
        display: none;
    }
    .user-dropdown-content {
        position: absolute;
        right: 0px;
        /*top:0px;*/
        height: calc(100vh - 90px);
        padding: 0;
    }
    .topbar-user{
        height: 250px;
        background-color: var(--cinza);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /*flex-wrap: wrap;*/
        padding: 10px;
        color: var(--branco)
    }
    .user-dropdown-button-img-mobile{
        /*max-height: 50%;
        max-width: 50%;*/
        max-width:80%; 
        max-height: 100%;
        border-radius: 60px;

    }
    .perfil{
        display: none;
    }
}

</style>