<template>
    <div class="index-title">
        <img class="logo" src="@/assets/logo_px40_clara.png"/>
        <div class="index-title-buttons">
            <IndexTopbarButton class="index-title-buttons-solucoes" title="Soluções" v-on:click="scroll"/>
            <IndexTopbarButton title="Entrar" v-on:click="open"/> 
        </div>
    </div>
</template>

<script>
import IndexTopbarButton from '@/components/Index/IndexTopbarButton'
export default {
    name: 'IndexTitle',
    components: { IndexTopbarButton},
    methods: {
        open(){
            this.$store.dispatch('login.open');
        },
        scroll(){
            document.querySelector('.index-solutions').scrollIntoView();
        }
    }
}
</script>

<style scoped>
.index-title{
    height: 70px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 100px;
    position: absolute;
    /*background-color: brown;*/
}
.index-title-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2em;
    font-size: 0.8em;
    color: var(--branco);
}
.logo{
    height: 70px;
    padding-top: 1em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    align-items: left;
    display: flex;
}
.index-title-buttons-solucoes{
    display: block;
    color: var(--branco);
}


@media only screen and (max-width: 600px) {
    .index-title {
        padding: 5px 5px;
        display:flex;
        flex-direction: column;
    }
    .logoflexxs{
        width: 15em;
        padding:0.1em;
        padding-top: 30px;
    }   
    .index-title-buttons-solucoes{
        display: none;
    }
    .index-title-buttons{
        align-content: center;
        margin-right: 0em;
    }

}

</style>