import Projeto from '@/models/projeto'
import { url } from '@/configs/global'

export default {
    state: () => ({
        projetos: [],
    }),
    mutations: {
        'projetos.set': function(state, {projetos}){
            if(projetos != undefined) state.projetos = projetos
        },
    },
    getters: {
        'projetos.get': function(state){
            return state.projetos
        }
    },
    actions: {
        'projetos.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_projects`)
			.then((response) => {
                commit('projetos.set', {
                    projetos: response.data.data.map(obj => new Projeto(obj, this.axios)).sort((a, b) => a.description.localeCompare(b.description))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}