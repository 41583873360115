<template>
    <div class="index-footer">
        <img class="logo" src="@/assets/logo_px40_clara.png"/>
        <div class="index-footer-title">EMPRESA
            <ul class="index-footer-title-sub">
                <li>Laboratório</li>
                <li>Inscrever-se</li>
            </ul>
        </div>
        <div class="index-footer-title">CONTATOS
            <ul class="index-footer-title-sub">
                <li>contato@px40.com.br</li>
                <li>São Paulo</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexFooter',
    components: { },
}
</script>

<style scoped>
.index-footer{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--cinza);
    height: auto;
    display: grid;
    padding: 1em;
    grid-template-columns: 25% 1fr 1fr ;
}

.logo{
    margin-left: 3em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 1em;
    width: 50%;
}

.index-footer-title{
    margin-top: 1em;
    margin-left: 3em;
    width: 100%;
    height: auto;
    font-size: 1em;
    text-align: left;
    color: var(--azul);
}

.index-footer-title-sub{
    margin-top: 1.5em;
    color: white;
    line-height: 1.6;
}

@media only screen and (max-width: 600px) {
  .index-footer {
      grid-template-columns: 1fr;
  }
  .logo {
     width: 50%;
     margin-left: 3em;
  }
}


</style>