import Plano from '@/models/plano'
import { url } from '@/configs/global'

export default {
    state: () => ({
        planos: [],
    }),
    mutations: {
        'planos.set': function(state, {planos}){
            if(planos != undefined) state.planos = planos
        },
    },
    getters: {
        'planos.get': function(state){
            return state.planos
        }
    },
    actions: {
        'planos.load': function( { dispatch, getters, commit}){
            dispatch('loading.show');
			const id_e = getters['empresa.get'].id
			this.axios.get(`${url}/api/${id_e}/get_plan_by_company_id`)
			.then((response) => {
                commit('planos.set', {
                    planos: response.data.data.map(obj => new Plano(obj, this.axios)).sort((a, b) => a.description.localeCompare(b.description))
                })
			}).catch((err)=>{
				dispatch('toast.error', err);
			}).finally(()=>{
				setTimeout(() => {
					dispatch('loading.hide');
				}, 300);
			})
        }
    }
}