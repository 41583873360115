export default {
    state: () => ({
        show: false,
        objetos: [],
        parente: [],
        aba: {}
    }),
    mutations: {
        'documentos.set': function(state, {show, objetos, parente, aba}){
            if(show != undefined) state.show = show
            if(objetos != undefined) state.objetos = objetos
            if(parente != undefined) state.parente = parente
            if(aba != undefined) state.aba = aba
        }
    },
    getters: {
        'documentos.getShow': function(state){
            return state.show
        },
        'documentos.getObjetos': function(state){
            return state.objetos
        },
        'documentos.getParente': function(state){
            return state.parente
        },
        'documentos.getAba': function(state){
            return state.aba
        }
    },
    actions: {
		'documentos.show': function({commit}, {objetos, parente, aba}){
            commit('documentos.set', { show: true, objetos, parente, aba})
        },
		'documentos.change_show': function( {commit}, value){
            commit('documentos.set', { show: value })
        }
    }
}