<template>
	<FloatButton v-if="aba.tipo!='principal'" @click="refresh" icon="refresh" type="vuestic"/>
	<FloatButton v-if="aba.model" @click="add()" icon="add" type="vuestic" style="bottom: 90px;"/>
   <div class="system-empresa">
		<div class="system-empresa-div" v-if="this.aba.tipo=='principal'">
			<!--{{getObjetos}}-->
			<EmpresaInfo :empresa="getObjetos"/>
		</div>
		<div class="system-empresa-div" v-else-if="this.aba.tipo">
			<EmpresaEstatistica v-if="aba.tipo == 'mantenedores'" :objetos="getObjetos" :aba="this.aba" img='servicos.svg' caption='"O segredo de um grande sucesso está no trabalho de uma grande equipe."' />
			<EmpresaTabela :aba="this.aba" :objetos="getObjetos"  />
		</div>
   </div>
</template>

<script>
import FloatButton from '@/utils/FloatButton'
import EmpresaTabela from '@/components/Empresa/EmpresaTabela'
import EmpresaEstatistica from '@/components/Empresa/EmpresaEstatistica'
import EmpresaInfo from '@/components/Empresa/EmpresaInfo'
export default {
    name: 'SystemEmpresa',
	components:{FloatButton, EmpresaTabela , EmpresaEstatistica, EmpresaInfo },
	data: () => ({
		
    }),
	computed: {
		getObjetos(){
			return  this.$store.getters[this.aba.getters]
		},
		aba(){
			const index = this.$store.getters['abas.getIndices'][this.$route.path]
			const abas = this.$store.getters['abas.getAbas'][this.$route.path]
			return index != undefined ? abas[index] : {}
		},
	},
	watch: {
		aba(){
			this.loadData()
		}
	},
	methods:{
		add(objeto){
			this.$store.dispatch('edit-modal.editShow', {
				objeto: objeto ? objeto : this.aba.model,
                callback: this.aba.callback
			})
		},
		refresh(){
			this.loadData()
		},
		loadData(){
			if (this.aba && this.aba.callback) this.$store.dispatch(this.aba.callback);
		}
	},
	mounted(){
		this.loadData()
	}
}
</script>

<style scoped>
.system-empresa {
	margin: 0px;
	width: 100%;
	height: 100%;
}
.system-empresa-div{
	height: 100%;
	padding-top: 10px;
}
</style>