<template>
    <div class="index-solutions-caption" >
        <div class="index-solutions-caption-title">{{title}}</div>
        <div class="index-solutions-caption-text">{{text}}</div>
    </div>
</template>

<script>
export default {
    name: 'IndexSolutionCaption',
    props:{
        title: String, 
        text: String,
    },
    components: { },
}
</script>

<style scoped>
.index-solutions-caption{
    width: 95%;
}

.index-solutions-caption-title{
    margin-top: 10vh;
    margin-left: 50px;
    height: auto;
    font-size: 3em;
    text-align: left;
    color: var(--azul);
}
.index-solutions-caption-text{
    width: 75%;
    margin-top: 4em;
    margin-right:  50px;
    margin-left: 50px;
    font-size: 1.2em;
    line-height: 1.6;
    text-align: left;
    color: var(--branco);
    display: flex;
}

@media only screen and (max-width: 600px) {
    .index-solutions-caption-text {
        text-align: center;
        margin-top: 2em;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: auto;
        font-size: 0.8em;
    }
    .index-solutions-caption-title{
        margin-left: auto;
        text-align: center;
        font-size: 2em;
    }
    .index-solutions-caption-title{
        margin-top: 1em;
    }
}


</style>