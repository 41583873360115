<template>
   	<div class="float-button">
		<slot name="content">
			<fa      v-if="icon != undefined && (type == 'fa'||type == undefined)" :icon="icon"/>
			<va-icon v-if="icon != undefined && type == 'vuestic'" :name="icon"/>
		</slot>
	</div>
</template>

<script>
export default {
    name: 'FloatButton',
	props: ["icon", "type"]
}
</script>

<style scoped>
.float-button{
	background-color: var(--verde);
	position: fixed;
	bottom: 20px;
	right: 25px;
	padding: 5px;
	border-radius: 100px;
	height: 50px;
  	aspect-ratio: 1 / 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--cinza);
	z-index: 1000;
}
.float-button:hover{
	cursor: pointer;
	box-shadow: 0 0 10px rgba(255,255,255,0.3);
}
</style>