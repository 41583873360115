import { useToast } from 'vue-toastification'
import Usuario from '../models/usuario'
import { url } from '@/configs/global'

const toast = useToast()

export default {
    state: () => ({
        show: false,
        loading: false
    }),
    mutations: {
        'login.set': function(state, show){
            state.show = show
        },
        'login.setLoading': function(state, loading){
            state.loading = loading
        }
    },
    getters: {
        'login.get': function(state){
            return state
        },
        'login.getLoading': function(state){
            return state.loading
        }
    },
    actions: {
        'login.confirm': function({ dispatch, commit }, {USUARIO, SENHA}){
            commit('login.setLoading', true)
            const data = {
                "grant_type": "password",
                "username": USUARIO,
                "client_id": 2,
                "client_secret": "Ej5apxFZUfRmwvKOtPlVBOcoQo6mLGnpK048Yu16",
                "password": SENHA
            }
            this.axios.post(`${url}/oauth/token`, data).then(async (response) => {
                const token = response.data.access_token
                localStorage.setItem('token_acesso', token)
                this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                commit('usuario.set', await Usuario.getLoggedUser(this.axios));
                dispatch('empresa.set', await Usuario.getAllCompanies(this.axios));
                dispatch('login.close', {logged: true});
                this.$router.push({path: '/bemvindo'});
            }).catch((err)=>{
                dispatch('login.logout');
                console.log(err)
                dispatch('toast.error', err.response.data.message);
            }).finally(()=>{
                commit('login.setLoading', false)
            })
        },
        'login.recuperar': function({ dispatch, commit }, BODY){
            commit('login.setLoading', true)
            this.axios.post(`${url}/usuario-recuperacao`, BODY).then(async () => {
                dispatch('toast.success', `Nova senha enviada pelo ${BODY.METODO}`);
            }).catch((err)=>{
                dispatch('toast.error', err);
            }).finally(()=>{
                commit('login.setLoading', false)
            })
        },
        'login.change': function({commit},value){
            commit( 'login.set', value );
        },
        'login.open': function({commit}){
            commit( 'login.set', true );
        },
        'login.close': function({commit, dispatch},res){
            if (res != undefined){
                if (res.logged){
                    toast.success('Login realizado com sucesso')
                }else{
                    dispatch('toast.error', res.msg)
                }
            }
            commit( 'login.set', false );
        },
        'login.logout': function({commit}, path){
            commit('usuario.setLoading', false)
            localStorage.removeItem('token_acesso');
            localStorage.removeItem('id_empresa');
            delete this.axios.defaults.headers.common['Authorization']
            this.$router.push({path: path ? path : '/'});
        },
        'login.register': async function({ dispatch, commit }, {USUARIO, SENHA, NOME, CONFIRMACAO}){
            if (CONFIRMACAO != SENHA) return dispatch('toast.error', 'Senhas devem ser iguais!');
            commit('login.setLoading', true)
            try {
                let usuario = new Usuario({name: NOME, password: SENHA, email: USUARIO}, this.axios)
                await usuario.create()
                dispatch('toast.success', 'Usuário cadastrado!');
            } catch (error) {
                dispatch('toast.error', error);
            }
            commit('login.setLoading', false)
        },
    }
}