import Usuario from "../models/usuario"

export default {
    state: () => ({
        usuario: {},
        loading: false,
    }),
    mutations: {
        'usuario.set': function(state, usuario){
            state.usuario = new Usuario(usuario, this.axios)
        },
        'usuario.setLoading': function(state, loading){
            state.loading = loading
        },
    },
    getters: {
        'usuario.get': function(state){
            return state.usuario
        },
        'usuario.getLoading': function(state){
            return state.loading
        }
    },
    actions: {
        'usuario.validate': async function({ dispatch, commit }){
            try {
                commit('usuario.setLoading', true)
                let token_acesso = localStorage.getItem('token_acesso')
                let id_empresa = localStorage.getItem('id_empresa')
                if (token_acesso != undefined && token_acesso != null){
                    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token_acesso}`
                    commit('usuario.set', await Usuario.getLoggedUser(this.axios));
                    dispatch('empresa.set', await Usuario.getAllCompanies(this.axios));
                    if (id_empresa != undefined && id_empresa != null){
                        dispatch('empresa.select', id_empresa)
                    }
                }else{
                    dispatch('login.logout')
                }
            } catch (error) {
                console.log(error.response.data)
                //dispatch('login.logout')
            }
            setTimeout(()=>commit('usuario.setLoading', false), 500)
        }
    }
}