<template>
	<FloatButton @click="refresh" icon="refresh" type="vuestic"/>
	<span v-if="aba.tipo != 'relatorios'">
		<FloatButton @click="add()" icon="add" type="vuestic" style="margin-bottom: 70px;"/>
		<FloatButton @click="showAll = !showAll" :icon="showAll? 'visibility_off' : 'visibility'" type="vuestic" style="margin-bottom: 140px;"/>
	</span>
	<ModalDocumentos />
   <div class="system-documentos">
		<DocumentosTabela :aba="aba" :objetos="getObjetos.filter((obj)=>obj.company_id==id_empresa || showAll || aba.tipo=='relatorios')"/>
   </div>
</template>

<script>
import FloatButton from '@/utils/FloatButton'
import ModalDocumentos from '../components/Documentos/ModalDocumentos'
import DocumentosTabela from '../components/Documentos/DocumentosTabela'
export default {
    name: 'SystemDocumentos',
	data() {
		return {
			showAll: false
		}
	},
	components:{FloatButton, ModalDocumentos, DocumentosTabela},
	computed: {
		getObjetos(){
			return  this.$store.getters[this.aba.getters]
		},
		aba(){
			const index = this.$store.getters['abas.getIndices'][this.$route.path]
			const abas = this.$store.getters['abas.getAbas'][this.$route.path]
			return index != undefined ? abas[index] : {}
		},
		id_empresa(){
			return this.$store.getters['empresa.get'].id
		}
	},
	watch: {
		aba(){
			this.loadData()
		}
	},
	methods:{
		add(objeto){
			this.$store.dispatch('edit-modal.editShow', {
				objeto: objeto ? objeto : this.aba.model,
                callback: this.aba.callback
			})
		},
		refresh(){
			this.loadData()
		},
		loadData(){
			if (this.aba && this.aba.callback) this.$store.dispatch(this.aba.callback);
		},
	},
	mounted(){
		this.loadData()
	}
}
</script>

<style scoped>
.system-documentos {
	margin: 0px;
	width: 100%;
	height: 100%;
	color: white;
	padding-top: 10px;
}
</style>