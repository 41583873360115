<template>
   <div class="empresa-tabela" :id="aba.tipo == 'mantenedores'? 'mantenedores' : ''">
	    <va-data-table v-if="objetos" hoverable :items="objetos" :columns="columns">
			<template #cell(actions)="{ rowIndex }">
				<va-button color="secondary" flat icon="edit" @click="update(objetos[rowIndex])" size="small" />
			</template>
			<template v-if="aba.nome == 'Mantenedores'" #cell(source)="{ rowIndex }">
				{{objetos[rowIndex].source == 1 ? "Externo": objetos[rowIndex].source == 0 ? "Interno" : ""}}
			</template>
			<template v-if="aba.nome == 'Mantenedores'" #cell(coust_hour)="{ rowIndex }">
				R${{objetos[rowIndex].coust_hour}}
			</template>
			<template #cell(created_at)="{ rowIndex }">
				{{timestamp(objetos[rowIndex].created_at)}}
			</template>
			<template #cell(updated_at)="{ rowIndex }">
				{{timestamp(objetos[rowIndex].updated_at)}}
			</template>
		</va-data-table>
   </div>
</template>

<script>
export default {
    name: 'EmpresaTabela',
	props : ['aba', 'objetos'],
	computed: {
		columns(){
			if(this.aba.tipo == 'setores'){
				return [
					{ key: 'description' , label:'Nome', sortable: true },
					{ key: 'created_at' , label:'Criado em:', sortable: true },
					{ key: 'updated_at' , label:'Atualizado em:', sortable: true },
					{ key: 'actions', label: 'AÇÕES'}
				]
			}
			else if(this.aba.tipo == 'projetos'){
				return [
					{ key: 'description' , label:'Nome', sortable: true },
					{ key: 'created_at' , label:'Criado em:', sortable: true },
					{ key: 'updated_at' , label:'Atualizado em:', sortable: true },
					{ key: 'actions', label: 'AÇÕES'}
				]
			}
			else if(this.aba.tipo == 'membros'){
				return [
					{ key: 'name' , label:'Nome', sortable: true },
					{ key: 'email' , label:'Endereço eletrônico', sortable: true },
					{ key: 'created_at' , label:'Criado em:', sortable: true },
					{ key: 'updated_at' , label:'Atualizado em:', sortable: true },
				]
			}
			else if(this.aba.tipo == 'mantenedores'){
				return [
					{ key: 'name' , label:'Nome', sortable: true },
					{ key: 'function' , label:'Função', sortable: true },
					{ key: 'coust_hour' , label:'Custo', sortable: true },
					{ key: 'source', label: 'ORIGEM', sortable: true},
					//{ key: 'created_at' , label:'Criado em:', sortable: true },
					//{ key: 'updated_at' , label:'Atualizado em:', sortable: true },
					{ key: 'actions', label: 'AÇÕES'}	
				]
			}
			else return []
		}
	},
	methods:{
		update(objeto){
			this.$store.dispatch('edit-modal.editShow', {
				objeto: objeto,
                callback: this.aba.callback
			})
		},
		timestamp(time_str){
			var date = new Date(time_str)
			return date.toLocaleDateString('pt-BR')
		}
	}
}
</script>

<style scoped>
.empresa-tabela {
	background-color: var(--c1);
	color: var(--c3);
	min-height: 100%;
}
#mantenedores {
	min-height: 70%;
}
</style>