export default {
    state: () => ({
        ABAS: {
            '/sistema/patrimonio': [
                {nome: 'Ativos', tipo: 'ativos', model: 'ativo', callback: 'ativos.load'},
                {nome: 'Reports', tipo: 'reports', model: 'report', callback: 'reports.load'},
                {nome: 'Serviços', tipo: 'servicos', model: 'servico', callback: 'servicos.load'},
            ],
            '/sistema/empresa': [
                {nome: 'Principal', tipo: 'principal', model: null, callback: null, getters: 'empresa.get'},
                {nome: 'Setores', tipo: 'setores', model: 'setor', callback: 'setores.load', getters: 'setores.get'},
                {nome: 'Projetos', tipo: 'projetos', model: 'projeto', callback: 'projetos.load', getters: 'projetos.get'},
                {nome: 'Membros', tipo: 'membros', model: null, callback: 'membros.load', getters: 'membros.get'},
                {nome: 'Mantenedores', tipo: 'mantenedores', model: 'mantenedor', callback: 'mantenedores.load', getters: 'mantenedores.get'},
            ],
            '/sistema/documentos': [
                {nome: 'Planos', tipo: 'planos', model: 'plano', callback: 'planos.load', getters: 'planos.get'},
                {nome: 'Checklists', tipo: 'checklists', model: 'checklist', callback: 'checklists.load', getters: 'checklists.get'},
                {nome: 'Relatórios', tipo: 'relatorios', model: null, callback: 'relatorios.load', getters: 'relatorios.get'},
            ],
        },
        INDICES: {},
        FILTRO: {}
    }),
    mutations: {
        'abas.set': function(state, arg){
            const key = arg[0]
            const val = arg[1]
            state.INDICES[key] = val
        },
        'abas.setFiltro': function(state, arg){
            const key = arg[0]
            const val = arg[1]
            state.FILTRO[key] = val
        }
    },
    getters: {
        'abas.getIndices': function(state){
            return state.INDICES
        },
        'abas.getAbas': function(state){
            return state.ABAS
        },
        'abas.getFiltro': function(state){
            return state.FILTRO
        }
    },
    actions: {
        'abas.set': function({commit}, arg){
            commit('abas.set', arg)
        },
        'abas.setFiltro': function({commit}, arg){
            commit('abas.setFiltro', arg)
        }
    }
}