<template>
   	<va-modal v-model="editShow" :hide-default-actions="true" :mobile-fullscreen="false">
	<div class="edit-modal">
		<div v-if="objeto.update != undefined" class="edit-modal-titulo">{{isEdit? 'Editar' : 'Adicionar'}} {{objeto.model_type}}</div>
		<span v-for="value in getLista" :key="value.label">
			<va-select v-if="value.type == 'select'" color="secondary" class="mb-3" :label="value.labelInput ? value.labelInput : value.label" v-model="objeto[value.label]" :options="getOptions(value)" :text-by="value.textBy" :value-by="value.valueBy" :track-by="value.trackBy" @update:model-value="value.change!=undefined ? value.change() : ()=>{}">
				<template v-if="value.append" #append>
					<va-button color="verde" @click="objeto.onEnter(value)" class="ml-3">Enviar</va-button>
				</template>
			</va-select>	
			<span v-else-if="value.type == 'colorinput'" style="display: flex; justify-content: end;">
				<input type="color" :value="objeto[value.label]" @change="(event) => objeto[value.label] = event.target.value" :config="{enableTime: true}">
			</span>	
			<span v-else-if="value.type == 'option-list' && getOptions(value).length > 0">
				<va-option-list color="secondary" class="mb-3" :label="value.labelInput ? value.labelInput : value.label" v-model="objeto[value.label]" :options="getOptions(value)" :text-by="value.textBy" :value-by="value.valueBy" :track-by="value.trackBy"/>
			</span>
			<span v-else-if="value.type == 'date-input' && value.vIF(objeto)" class="date-input-span" >
				<va-date-input color="secondary" class="mb-3" :label="value.labelInput ? value.labelInput : value.label" v-model="objeto[value.label]"/>
				<va-time-input v-if="value.time" v-model="objeto[value.label + '-time']"/>
			</span>
			<span v-else-if="value.type == 'datetime'">
				<va-datetime type="string" color="secondary" class="mb-3" :label="value.labelInput ? value.labelInput : value.label" v-model="objeto[value.label]"/>
			</span>
			
			<va-input v-else-if="value.type == 'text'" :type="value.inputType ? value.inputType : 'text'" color="secondary" class="mb-3" :label="value.labelInput ? value.labelInput : value.label" v-model="objeto[value.label]" :readonly="value.readonly">
				<template v-if="value.append" #append>
					<va-button color="verde" @click="objeto.onEnter(value)" class="ml-3">Enviar</va-button>
				</template>
			</va-input>	
		</span>
		<va-data-table color="secondary" v-show="isEdit && objeto.model_type == 'usuario'" :items="getEmpresas" :columns="columnsMembro">
			<template #cell(ACESSO)="{ rowIndex }">
				<va-select v-model="listaDeSelecao[rowIndex]" width="130px"
					:options="[{ACESSO: null, ID: getEmpresas[rowIndex].ID}, {ACESSO: 1, ID: getEmpresas[rowIndex].ID}]" :text-by="(option)=> option.ACESSO == null ? 'Sem acesso' : 'Com acesso'" track-by="ACESSO"/>
			</template>
			<template #cell(NIVEL_DE_ACESSO)="{ rowIndex }">
				{{listaDeSelecao[rowIndex]==undefined || listaDeSelecao[rowIndex].ACESSO == null ? '' : listaDeSelecao[rowIndex].ACESSO == 1 ? 'Visualização' : 'Acionamento' }}
			</template>
		</va-data-table>
		<va-button v-if="objeto.update != undefined || !isEdit" color="verde" text-color="cinza" class="mt-3" @click="confirm">{{isEdit? 'Atualizar' : 'Criar'}}</va-button>
		<va-button v-if="isEdit && objeto.allowRemove" color="laranja" class="mt-3" @click="remove" text-color="cinza">Remover</va-button>
		<span class="botoes-definicoes" v-if="isEdit && getAdmin == 1 && objeto.model_type == 'definicoes'">
			<va-button class="mr-1 ml-1" @click="send_config('reset')" color="laranja"><va-icon name="warning" size="small" class="mr-2"/> Reiniciar placa</va-button>
			<va-button class="mr-1 ml-1" @click="send_config('delete_sd')" color="laranja"><va-icon name="warning" size="small" class="mr-2"/> Remover dados do cartão</va-button>
		</span>
	</div>
	</va-modal>
</template>

<script>
import VaDatetime from '@/utils/VaDatetime'
export default {
    name: 'EditModal',
	components:{VaDatetime},
	data: () => ({
        editShow: false,
		objeto: {},
		isEdit: false,
		listaDeSelecao: [],
		store_key: 'edit-modal',
		columnsMembro: [
			{key: 'NOME'},
			{key: 'ACESSO'},
			{key: 'NIVEL_DE_ACESSO', label: 'NIVEL DE ACESSO'},
		]
    }),
	computed: {
		getLista(){
			let lista = this.isEdit ? this.objeto.listaAtualizacao : this.objeto.listaCriacao
			return lista
		},
		getEmpresas(){
			return this.$store.getters['admin.getEmpresas']
		},
		getEditShow(){
			return this.$store.getters[`${this.store_key}.getEditShow`];
		},
		getEditObjeto(){
			return this.$store.getters[`${this.store_key}.getEditObjeto`];
		},
		getAdmin(){
			return this.$store.getters['usuario.get'].ADMIN
		}
	},
	methods:{
		getOptions(value){
			try {
				const res = value.options({getters: this.$store.getters})
				if (res == null || res == undefined) throw 'error'
				return res
			} catch (error) {
				return []
			}
		},
		async confirm(){
			try {
				this.$store.dispatch('loading.show')
				if (this.isEdit){
					this.objeto.listaDeSelecao = this.listaDeSelecao
					this.objeto.todas_empresas = this.getEmpresas
					await this.objeto.update({
						getters: this.$store.getters
					})
				}else{
					await this.objeto.create({
						getters: this.$store.getters
					})
				}
				
				this.$store.dispatch('toast.success', 'Atualizado');
				try {
					const callback = this.$store.getters['edit-modal.getCallback']
					this.$store.dispatch(callback)
				} catch (error) {
					console.log(error)
				}
				this.editShow = false
			} catch (err) {
				this.$store.dispatch('toast.error', err);
			}	
			this.$store.dispatch('loading.hide')
		},
		
		async remove(){
			if (confirm("Tem certeza que deseja remover?") == true) {
				try {
					this.$store.dispatch('loading.show')
					await this.objeto.delete({
						getters: this.$store.getters
					})
					this.$store.dispatch('toast.success', 'Removido');
				} catch (err) {
					this.$store.dispatch('toast.error', err);
				}	
				try {
					const callback = this.$store.getters['edit-modal.getCallback']
					this.$store.dispatch(callback)
				} catch (error) {
					console.log(error)
				}
				this.editShow = false
				this.$store.dispatch('loading.hide')
			}
		},

		async send_config(msg){
			await this.objeto.send_config(msg)
		}
	},
    watch: {
        editShow: function (value) {
            this.$store.dispatch(`${this.store_key}.change_editShow`, value);
        },
        getEditShow: function(value){
            this.editShow = value
        },
        getEditObjeto: function(value){
			this.objeto = value
			if (value.id != undefined){
				this.isEdit = true
				if(value.model_type == 'usuario'){
					this.listaDeSelecao = this.getEmpresas.map((e)=>{
						let membro = value.EMPRESAS_MEMBRO.filter((f)=>f.ID_E == e.ID)
						return { 
							ACESSO: membro.length == 0 ?  null : membro[0].NIVEL_ACESSO_MEMBRO, 
							ID: e.ID
						}
					})
				}
			}else{
				this.isEdit = false
			}
        }
    }
}
</script>

<style scoped>
.edit-modal{
	display: flex;
	flex-direction: column;
	color: var(--branco);
	min-width: 30vw;
}
.edit-modal-titulo{
	margin-bottom: 25px;
	text-transform: uppercase; 
}
.botoes-definicoes{
	margin-top: 10px;
	display: flex;
	justify-content: space-evenly;
}
.date-input-span{
	display: flex;
	flex-direction: row;
}
</style>