<template>
   	<va-modal v-model="showModal" :hide-default-actions="true" :mobile-fullscreen="false" :title="getTitle">
	<div class="modal-evidencias">
		<label for="inputTag" class="input-label">
		Selecione a imagem
		<input type="file" id="inputTag" @change="onFileSelect">
		</label>
		<div v-if="selectedFile" style="margin-bottom: 30px;">
			<VueCropper ref="cropper" :src="selectedFile" alt="Source Image"></VueCropper>
			<va-button class="mt-3 mr-3" color="verde" text-color="cinza" @click="saveImage(1)">Cortar e enviar</va-button>
			<va-button class="mt-3 mr-3" v-if="this.getObjeto.model_type == 'report'" color="laranja" text-color="cinza" @click="saveImage(0)">Cortar e enviar</va-button>
			<va-button class="mt-3" @click="selectedFile = null" text-color="cinza" color="azul">Cancelar</va-button>
		</div>
		<va-inner-loading class="modal-evidencias-imgs" :loading="loading">
			<div v-for="img in photos" :key="img.id" class="modal-evidencias-img">
				<div class="container" :style= getStyle(img.type)>
					<img :src="img.url" class="image">
					<div class="overlay">
						<va-button color="verde" text-color="cinza" class="mb-3" @click="showImg(img.url)">Abrir</va-button>
						<va-button @click="deletePhoto(img.id)" color="laranja" text-color="cinza">Excluir</va-button>
					</div>
				</div>
			</div>
		</va-inner-loading>
	</div>
	</va-modal>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
    name: 'ModalEvidencias',
	components: {VueCropper, },
	data: () => ({
        showModal: false,
		selectedFile: null,
		photos: [],
		loading: false
    }),
	computed: {
		getShow(){
			return this.$store.getters[`evidencias.getShow`];
		},
		getObjeto(){
			return this.$store.getters[`evidencias.getObjeto`];
		},
		getTitle(){
			const type = this.getObjeto.model_type
			if (type == 'ativo') return `Evidências de ativos`
			if (type == 'report') return `Evidências de reports`
			if (type == 'servico') return `Evidências de serviços`
			return 'Evidências'
		}, 
	},
	methods:{
		deletePhoto(id_image){
			if (!confirm('Tem certeza?')) return
			this.loading=true
			this.getObjeto.deletePhoto({
				getters: this.$store.getters, id_image: id_image
			}).then(async () => {
				this.$store.dispatch('toast.success', 'Removido!')
				await this.getPhotos()
			}).catch(function (error) {
				console.log(error)
				this.$store.dispatch('toast.error', 'Erro!')
			}).finally(()=>this.loading=false)
		},
		onFileSelect(e) {
			const file = e.target.files[0]
			this.mime_type = file.type
			const reader = new FileReader()
			reader.onload = (event) => this.selectedFile = event.target.result
			reader.readAsDataURL(file)
		},
		saveImage(type) {
			this.loading = true
			this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
				this.getObjeto.savePhoto({
					getters: this.$store.getters,
					blob,
					type
				}).then(async() => {
					await this.getPhotos()
					this.selectedFile = null
					this.$store.dispatch('toast.success', 'Enviado!')
				}).catch(function (error) {
					console.log(error)
					console.log(error.data)
					this.$store.dispatch('toast.error', 'Erro!')
				}).finally(()=>this.loading=false)
			}, this.mime_type)
		},
		async getPhotos(){
			this.loading = true
			this.photos = await this.getObjeto.getAllPhotos({
				getters: this.$store.getters
			})
			this.loading = false
		},
		showImg(img){
			window.open(img.replace("&h=250&w=250", ""), "_blank");	
		},
		getStyle(imgType){
			const type = this.getObjeto.model_type
			if (type == 'report')
			 return (imgType  ? 'background-color: var(--verde)' : 'background-color: var(--laranja)')
			return 'padding: 0px;'
		}
	},
    watch: {
        getShow: function (value) {
            this.showModal = value
			this.selectedFile = null
			if (value){
				this.getPhotos()
			}
        },
		showModal: function (value) {
			if(!value) this.photos = []
            this.$store.dispatch(`evidencias.change_show`, value);
        },
    }
}
</script>

<style scoped>
input{
  display: none;
}
.modal-evidencias{
	display: flex;
	flex-direction: column;
	min-width: 30vw;
	color: var(--branco);
}
.modal-evidencias-imgs{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	min-height: 10vw;
}
.modal-evidencias-img{
	width: 33.333%;
	padding: 10px;
}

.container{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
}
.image{
	width: 100%;
	display: block;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #3C3F4A99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container:hover .overlay {
  opacity: 1;
}

.input-label{
	color: var(--cinza);
	background-color: var(--azul);
	text-align: center;
	padding: 10px;
	border-radius: 20px;
	font-weight:bold;
	margin-bottom: 10px;
}
.input-label:hover{
	cursor: pointer;
}
</style>