import { createWebHistory, createRouter } from "vue-router";
import Index from '@/views/Index'
import Workspaces from '@/views/Workspaces'
import System from '@/views/System'
import SystemPatrimonio from '@/views/Patrimonio'
import SystemEmpresa from '@/views/Empresa'
import SystemDocumentos from '@/views/Documentos'

const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index
    },
    {
        name: 'Workspaces',
        path: '/bemvindo',
        component: Workspaces
    },
    {
        name: 'System',
        path: '/sistema',
        component: System,
        children: [
            {
                path: '/sistema/patrimonio',
                name: 'SystemPatrimonio',
                component: SystemPatrimonio,
            },
            {
                path: '/sistema/empresa',
                name: 'SystemEmpresa',
                component: SystemEmpresa,
            },
            {
                path: '/sistema/documentos',
                name: 'SystemDocumentos',
                component: SystemDocumentos,
            },
        ]
    },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

export default router;
